import { InputHTMLAttributes } from "react";
import cn from "classnames";

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  title?: string;
  variant?: "sm" | "md";
}

export default function Input({
  className,
  title,
  variant = "md",
  ...props
}: InputProps) {
  return (
    <>
      {title && <p className="font-medium text-sm pb-1 m-0">{title}</p>}
      <input
        className={cn(
          "input input-bordered focus:outline-none focus:border-blue-500 border-grey-500 max-w-xs bg-grey-50 transition-colors",
          variant === "sm" &&
            "bg-white h-8 p-3 border-grey-100 focus:border-grey-300 text-sm",
          className
        )}
        {...props}
      />
    </>
  );
}

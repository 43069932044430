import { ReactElement, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Editor as EditorProps } from "@tiptap/react";
import toast, { Toaster } from "react-hot-toast";
import { toastOptions } from "../common/toastOptions";
import IconButton from "../common/IconButton";
import {
  BoldIcon,
  ItalicIcon,
  PaintBrushIcon,
  StrikethroughIcon,
  UnderlineIcon,
  ChevronDownIcon,
  ListBulletIcon,
  NumberedListIcon,
  ArrowUturnLeftIcon,
  ArrowUturnRightIcon,
} from "@heroicons/react/24/solid";
import {
  MinusIcon,
  ChatBubbleBottomCenterTextIcon,
  PencilIcon,
  XMarkIcon,
  ArrowUpOnSquareIcon,
} from "@heroicons/react/24/outline";
import Tooltip from "../common/Tooltip";
import Button from "../common/Button";
import axios from "axios";
import { useAuth } from "../../context/AuthContext";
import { SubscriptionStatus, SubscriptionStatusType } from "../../db/schema";
interface EditorMenuBarProps {
  editor: EditorProps | null;
  disabled?: boolean;
  caseId: string | undefined;
  score: number | null | undefined;
}

const iconBtnStyles = "size-4";

const copySuccessToast = () => {
  toast.success("Copied to clipboard");
};

function convertHtmlToPlainText(html: string): string {
  return (
    html
      // Remove style attributes
      .replace(/\s*style="[^"]*"/g, "")

      // Remove data attributes
      .replace(/\s*data-[^=]*="[^"]*"/g, "")

      // Handle headings with double line break after
      .replace(/<h[1-6]>(.*?)<\/h[1-6]>/g, "$1\n\n")

      // Handle blockquotes with newlines
      .replace(/<blockquote><p>(.*?)<\/p><\/blockquote>/g, '\n"$1"\n\n')

      // Handle unordered lists
      .replace(/<ul>(\s*<li><p>.*?<\/p><\/li>\s*)*<\/ul>/g, (match) => {
        return (
          "\n" +
          match
            .replace(/<ul>|<\/ul>/g, "")
            .replace(/<li><p>(.*?)<\/p><\/li>/g, "- $1\n") +
          "\n"
        );
      })

      // Handle ordered lists with custom start attribute
      .replace(
        /<ol(?:\s+start="(\d+)")?>(\s*<li><p>.*?<\/p><\/li>\s*)*<\/ol>/g,
        (match, startNum) => {
          let number = parseInt(startNum || "1") - 1;
          return (
            "\n" +
            match
              .replace(/<ol(?:\s+start="\d+")?>|<\/ol>/g, "")
              .replace(/<li><p>(.*?)<\/p><\/li>/g, (_, content) => {
                number++;
                return `${number}. ${content}\n`;
              }) +
            "\n"
          );
        }
      )

      // Handle paragraphs with double line break
      .replace(/<p>(.*?)<\/p>/g, "$1\n\n")

      // Handle horizontal rules
      .replace(/<hr\s*\/?>/g, "\n---\n\n")

      // Handle line breaks
      .replace(/<br\s*\/?>/g, " ")

      // Remove strikethrough content completely
      .replace(/<s>.*?<\/s>/g, "")

      // Remove formatting tags but keep their content
      .replace(/<(mark|u|span|em|strong)[^>]*>(.*?)<\/\1>/g, "$2")

      // Remove any remaining HTML tags
      .replace(/<[^>]+>/g, "")

      // Clean up excessive newlines while preserving paragraph breaks
      .replace(/\n{3,}/g, "\n\n")

      // Trim extra whitespace at start/end
      .trim()
  );
}

const EditorMenuBar = function ({
  editor,
  disabled,
  caseId,
  score,
}: EditorMenuBarProps) {
  const [isTextColorPickerOpen, setIsTextColorPickerOpen] = useState(false);
  const [isHighlightColorPickerOpen, setIsHighlightColorPickerOpen] =
    useState(false);
  const [isHeadingDropdownOpen, setIsHeadingDropdownOpen] = useState(false);
  const dialogRef = useRef<HTMLDialogElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const { user } = useAuth();
  const navigate = useNavigate();

  const [subscriptionStatus, setSubscriptionStatus] =
    useState<(typeof SubscriptionStatus.enumValues)[number]>();

  const [trialLogsRemaining, setTrialLogsRemaining] = useState<number>();

  useEffect(() => {
    setTrialLogsRemaining(user?.trial_logs_remaining);
    setSubscriptionStatus(
      user?.subscription_status as (typeof SubscriptionStatus.enumValues)[number]
    );

    if (
      user?.subscription_status ===
      SubscriptionStatusType.enum.incomplete_expired
    ) {
      navigate("/subscription?trial_expired=true");
    }
  }, [user?.trial_logs_remaining, user?.subscription_status, navigate]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsHeadingDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleCopyToClipboard = async () => {
    if (editor) {
      try {
        const content = editor.getHTML();

        await axios.post(
          "/api/audits",
          {
            userId: user?.id,
            caseId,
            content,
            score,
          },
          {
            withCredentials: true,
          }
        );

        await navigator.clipboard.writeText(convertHtmlToPlainText(content));
        dialogRef.current?.close();
        copySuccessToast();
      } catch (error) {
        console.error("Error in copy process:", error);
        toast.error(
          error.response?.status === 401
            ? "Please log in to export content"
            : "Failed to export content"
        );
      }
    }
  };

  type HeadingLevel = 1 | 2 | 3;
  interface HeadingOption {
    name: ReactElement;
    level: HeadingLevel | 0;
  }
  const headingLevels: HeadingOption[] = [
    { name: <p>Paragraph</p>, level: 0 },
    { name: <h1>Heading 1</h1>, level: 1 },
    { name: <h2>Heading 2</h2>, level: 2 },
    { name: <h3>Heading 3</h3>, level: 3 },
  ];

  if (editor == null) {
    return null;
  }

  const getCurrentHeading = () => {
    const heading = headingLevels.find((h) =>
      h.level === 0
        ? editor.isActive("paragraph")
        : editor.isActive("heading", { level: h.level })
    );
    return heading ? heading.name : "Paragraph";
  };

  const applyHeading = (level: HeadingOption["level"]) => {
    if (level === 0) {
      editor.chain().focus().setParagraph().run();
    } else {
      editor.chain().focus().toggleHeading({ level }).run();
    }
    setIsHeadingDropdownOpen(false);
  };

  return (
    <div className="sticky top-0 z-10 bg-gray-100 border-b-2">
      <div className="flex items-center justify-between w-full py-2 px-2">
        <div className="flex items-center space-x-1">
          <div className="relative inline-block" ref={dropdownRef}>
            <div>
              <IconButton
                type="button"
                id="heading-menu"
                aria-expanded="true"
                aria-haspopup="true"
                onClick={() => setIsHeadingDropdownOpen(!isHeadingDropdownOpen)}
                description="Styles"
                disabled={disabled}
              >
                <p className="text-xs">{getCurrentHeading()}</p>
                <ChevronDownIcon className={iconBtnStyles} aria-hidden="true" />
              </IconButton>
            </div>

            {isHeadingDropdownOpen && (
              <div
                className="origin-top-left absolute left-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10"
                role="menu"
                aria-orientation="vertical"
                aria-labelledby="heading-menu"
              >
                <div className="py-1" role="none">
                  {headingLevels.map((heading) => (
                    <button
                      key={heading.level}
                      className={`block w-full text-left px-4 py-2 text-xs prose ${
                        heading.level === 0
                          ? editor.isActive("paragraph")
                            ? "bg-gray-100 text-gray-900"
                            : "text-gray-700"
                          : editor.isActive("heading", { level: heading.level })
                          ? "bg-gray-100 text-gray-900"
                          : "text-gray-700"
                      } hover:bg-gray-100 hover:text-gray-900`}
                      role="menuitem"
                      onClick={() => applyHeading(heading.level)}
                    >
                      {heading.name}
                    </button>
                  ))}
                </div>
              </div>
            )}
          </div>
          <IconButton
            onClick={() => editor.chain().focus().toggleBold().run()}
            disabled={
              disabled || !editor.can().chain().focus().toggleBold().run()
            }
            isActive={editor.isActive("bold")}
            description="Bold"
          >
            <BoldIcon className={iconBtnStyles} />
          </IconButton>
          <IconButton
            onClick={() => editor.chain().focus().toggleItalic().run()}
            disabled={
              disabled || !editor.can().chain().focus().toggleItalic().run()
            }
            isActive={editor.isActive("italic")}
            description="Italic"
          >
            <ItalicIcon className={iconBtnStyles} />
          </IconButton>
          <IconButton
            onClick={() => editor.chain().focus().toggleUnderline().run()}
            disabled={
              disabled || !editor.can().chain().focus().toggleUnderline().run()
            }
            isActive={editor.isActive("underline")}
            description="Underline"
          >
            <UnderlineIcon className={iconBtnStyles} />
          </IconButton>
          <IconButton
            onClick={() => editor.chain().focus().toggleStrike().run()}
            disabled={
              disabled || !editor.can().chain().focus().toggleStrike().run()
            }
            isActive={editor.isActive("strike")}
            description="Strikethrough"
          >
            <StrikethroughIcon className={iconBtnStyles} />
          </IconButton>
          <Tooltip overlay="Text Color">
            <div className="relative inline-block">
              <IconButton isActive={isTextColorPickerOpen} disabled={disabled}>
                <PencilIcon className={iconBtnStyles} />
              </IconButton>
              <input
                type="color"
                onInput={(e) => {
                  editor
                    .chain()
                    .focus()
                    .setColor((e.target as HTMLInputElement).value)
                    .run();
                }}
                onFocus={() => setIsTextColorPickerOpen(true)}
                onBlur={() => setIsTextColorPickerOpen(false)}
                className="absolute inset-0 opacity-0 cursor-pointer"
              />
            </div>
          </Tooltip>
          <Tooltip overlay="Highlight color">
            <div className="relative inline-block">
              <IconButton
                isActive={isHighlightColorPickerOpen}
                disabled={disabled}
              >
                <PaintBrushIcon className={iconBtnStyles} />
              </IconButton>
              <input
                type="color"
                onInput={(e) => {
                  editor
                    .chain()
                    .focus()
                    .setHighlight({
                      color: (e.target as HTMLInputElement).value,
                    })
                    .run();
                }}
                onFocus={() => setIsHighlightColorPickerOpen(true)}
                onBlur={() => setIsHighlightColorPickerOpen(false)}
                className="absolute inset-0 opacity-0 cursor-pointer"
              />
            </div>
          </Tooltip>
          <IconButton
            onClick={() => editor.chain().focus().toggleBlockquote().run()}
            disabled={
              disabled || !editor.can().chain().focus().toggleBlockquote().run()
            }
            isActive={editor.isActive("blockquote")}
            description="Blockquote"
          >
            <ChatBubbleBottomCenterTextIcon className={iconBtnStyles} />
          </IconButton>
          <IconButton
            onClick={() => editor.chain().focus().setHorizontalRule().run()}
            disabled={
              disabled ||
              !editor.can().chain().focus().setHorizontalRule().run()
            }
            description="Horizontal line"
          >
            <MinusIcon className={iconBtnStyles} />
          </IconButton>
          <IconButton
            onClick={() => editor.chain().focus().toggleBulletList().run()}
            disabled={
              disabled || !editor.can().chain().focus().toggleBulletList().run()
            }
            isActive={editor.isActive("bulletList")}
            description="Bullet list"
          >
            <ListBulletIcon className={iconBtnStyles} />
          </IconButton>
          <IconButton
            onClick={() => editor.chain().focus().toggleOrderedList().run()}
            disabled={
              disabled ||
              !editor.can().chain().focus().toggleOrderedList().run()
            }
            isActive={editor.isActive("orderedList")}
            description="Ordered list"
          >
            <NumberedListIcon className={iconBtnStyles} />
          </IconButton>
          {/* <IconButton
            onClick={() => editor.chain().focus().undo().run()}
            disabled={disabled || !editor.can().chain().focus().undo().run()}
            description="Undo"
          >
            <ArrowUturnLeftIcon className={iconBtnStyles} />
          </IconButton>
          <IconButton
            onClick={() => editor.chain().focus().redo().run()}
            disabled={disabled || !editor.can().chain().focus().redo().run()}
            description="Redo"
          >
            <ArrowUturnRightIcon className={iconBtnStyles} />
          </IconButton> */}
        </div>
        <div className="flex justify-end">
          {subscriptionStatus === "trialing" && (
            <div className="p-2 text-blue-500">
              <b>{trialLogsRemaining}</b> free reports/evaluations remaining
            </div>
          )}
          <div className="col-span-3 dropdown dropdown-end">
            {/* <IconButton
              tabIndex={0}
              description="More"
              tooltipPosition="bottomRight"
            >
              <EllipsisHorizontalIcon className={iconBtnStyles} />
            </IconButton> */}
            <Button
              className="btn-primary w-full"
              variant="primary"
              size="sm"
              disabled={disabled}
            >
              <div className="flex items-center">
                <ArrowUpOnSquareIcon className="size-6 mr-1" />
                <p>Export</p>
              </div>
            </Button>
            <ul
              tabIndex={0}
              className="dropdown-content menu bg-base-100 rounded-lg z-[1] w-52 p-2 shadow"
            >
              <li>
                <p
                  onClick={() => dialogRef.current?.showModal()}
                  className="hover:bg-gray-100"
                >
                  {/* <MicrophoneIcon className="size-4" /> */}
                  Copy
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <dialog ref={dialogRef} className="modal">
        <div className="modal-box prose w-5/12 p-8">
          <h2>Review and acknowledge</h2>
          <p>
            I affirm that this report, generated with the assistance of Police
            Narratives Al, has been thoroughly reviewed and edited by me to
            ensure its accuracy in representing my recollection of the reported
            events. I acknowledge the potential legal implications of this
            statement and, if required, am prepared to testify to its accuracy.
          </p>
          <form method="dialog">
            <button className="btn absolute top-4 right-4 z-10 border-0 shadow-none">
              <XMarkIcon className="size-6" />
            </button>
            <Button
              onClick={handleCopyToClipboard}
              className="btn-primary w-full"
              variant="primary"
            >
              Agree and copy to clipboard
            </Button>
          </form>
        </div>
      </dialog>
      {/* <ToastContainer
        position="bottom-center"
        transition={Slide}
        autoClose={3000}
      /> */}
      <Toaster position="bottom-center" toastOptions={toastOptions} />
    </div>
  );
};

export default EditorMenuBar;

import { ButtonHTMLAttributes, ReactNode } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Button from "../common/Button";
import { Cog8ToothIcon, DocumentTextIcon, TrashIcon } from "@heroicons/react/24/outline";
import cn from "classnames";

interface MenuItemProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: ReactNode;
  page: string;
  active?: boolean;
}

function renderIcon(page: string): ReactNode {
  switch (page) {
    case "cases":
      return <DocumentTextIcon className="size-5 mr-1" />;
    case "trash":
      return <TrashIcon className="size-5 mr-1" />;
    case "settings":
      return <Cog8ToothIcon className="size-5 mr-1" />;
    default:
      break;
  }
}

export default function MenuItem({
  children,
  className,
  page,
  active,
  ...props
}: MenuItemProps) {
  const navigate = useNavigate();
  const location = useLocation();
  const isActive = location.pathname.startsWith(`/${page}`);

  return (
    <Button
      onClick={() => navigate(`/${page}`)}
      className={cn(
        "btn border-0 h-[25px] w-full justify-start !min-h-10 shadow-none",
        isActive && "bg-blue-50 hover:bg-blue-50 text-blue-600",
        !isActive && "hover:bg-gray-200",
        className
      )}
      {...props}
    >
      <div className="flex items-center pl-2">
        {renderIcon(page)}
        {children}
      </div>
    </Button>
  );
}

import {
  pgTable,
  text,
  varchar,
  timestamp,
  uuid,
  date,
  time,
  jsonb,
  pgEnum,
  boolean,
  integer,
  decimal,
} from "drizzle-orm/pg-core";
import { z } from "zod";

// Define the platform enum
export const SubscriptionPlatform = pgEnum("subscription_platform", [
  "stripe",
  "ios",
  "android",
  "google",
]);

export const SubscriptionPlatformType = z.enum(SubscriptionPlatform.enumValues);

export const SubscriptionStatus = pgEnum("subscription_status", [
  "active",
  "past_due",
  "canceled",
  "incomplete",
  "incomplete_expired",
  "trialing",
  "unpaid",
  "paused",
]);

export const SubscriptionStatusType = z.enum(SubscriptionStatus.enumValues);

export const ExternalAuthProvider = pgEnum("auth_provider", ["ios", "google"]);

export const ExternalAuthProviderType = z.enum(ExternalAuthProvider.enumValues);

export const users = pgTable("users", {
  id: uuid("id").primaryKey().defaultRandom(),
  stripe_customer_id: varchar("stripe_customer_id", { length: 255 }),
  subscription_status: varchar("subscription_status", { length: 50 }),
  name: text("name"),
  email: varchar("email", { length: 255 }).notNull().unique(),
  password: varchar("password", { length: 255 }),
  badgeID: varchar("badge_id", { length: 255 }),
  department: varchar("department", { length: 255 }),
  avatarUrl: text("avatar_url"),
  external_auth_id: varchar("external_auth_id", { length: 255 }),
  external_auth_provider: ExternalAuthProvider("external_auth_provider"),
  created_at: timestamp("created_at").defaultNow(),
  updated_at: timestamp("updated_at").defaultNow(),
  state: varchar("state", { length: 255 }),
  trial_logs_remaining: integer("trial_logs_remaining").notNull().default(5),
});

export type User = typeof users.$inferSelect;
export type NewUser = typeof users.$inferInsert;

export const subscriptions = pgTable("subscriptions", {
  id: uuid("id").primaryKey().defaultRandom(),
  user_id: uuid("user_id")
    .notNull()
    .references(() => users.id),
  stripe_subscription_id: varchar("stripe_subscription_id", {
    length: 255,
  }).unique(),
  status: SubscriptionStatus("status").notNull(),
  current_period_end: timestamp("current_period_end").notNull(),
  cancel_at_period_end: boolean("cancel_at_period_end").default(false),
  metadata: jsonb("metadata"), // Platform info goes here
  created_at: timestamp("created_at").defaultNow(),
  updated_at: timestamp("updated_at").defaultNow(),
});

export type Subscription = typeof subscriptions.$inferSelect;
export type NewSubscription = typeof subscriptions.$inferInsert;

export const subscription_invoices = pgTable("subscription_invoices", {
  id: uuid("id").primaryKey().defaultRandom(),
  user_id: uuid("user_id")
    .notNull()
    .references(() => users.id),
  stripe_invoice_id: varchar("stripe_invoice_id", { length: 255 }).unique(),
  amount: decimal("amount", { precision: 10, scale: 2 }),
  status: varchar("status", { length: 50 }).notNull(),
  period_start: timestamp("period_start").notNull(),
  period_end: timestamp("period_end").notNull(),
  metadata: jsonb("metadata"),
  created_at: timestamp("created_at").defaultNow(),
});

export type SubscriptionInvoice = typeof subscription_invoices.$inferSelect;
export type NewSubscriptionInvoice = typeof subscription_invoices.$inferInsert;

export const CaseStatus = pgEnum("case_status", [
  "active",
  "archived",
  "discarded",
]);

export const CaseStatusType = z.enum(CaseStatus.enumValues);

export const cases = pgTable("cases", {
  id: uuid("id").primaryKey().defaultRandom(),
  userId: uuid("user_id")
    .notNull()
    .references(() => users.id),
  caseNumber: varchar("case_number", { length: 50 }),
  incidentDate: date("incident_date"),
  incidentTime: time("incident_time"),
  status: varchar("status", { length: 20 }).default("active"),
  content: jsonb("content"),
  audioUrl: text("audio_url"),
  createdAt: timestamp("created_at", { withTimezone: true }).defaultNow(),
  updatedAt: timestamp("updated_at", { withTimezone: true }).defaultNow(),
  discardedAt: timestamp("discarded_at", { withTimezone: true }),
});

export type Case = typeof cases.$inferSelect;
export type NewCase = typeof cases.$inferInsert;

export const suggestions = pgTable("suggestions", {
  id: uuid("id").primaryKey().defaultRandom(),
  userId: uuid("user_id")
    .notNull()
    .references(() => users.id),
  caseId: uuid("case_id")
    .notNull()
    .references(() => cases.id),
  content: text("content").notNull(),
  priority: integer("priority"),
  archived: boolean("archived").default(false),
  implemented: boolean("implemented").default(false),
  createdAt: timestamp("created_at", { withTimezone: true }).defaultNow(),
  updatedAt: timestamp("updated_at", { withTimezone: true }).defaultNow(),
});

export type Suggestion = typeof suggestions.$inferSelect;
export type NewSuggestion = typeof suggestions.$inferInsert;

export const caseScores = pgTable("case_scores", {
  id: uuid("id").primaryKey().defaultRandom(),
  caseId: uuid("case_id")
    .notNull()
    .references(() => cases.id),
  archived: boolean("archived").default(false),
  createdAt: timestamp("created_at", { withTimezone: true }).defaultNow(),
  score: integer("score"),
});

export type CaseScore = typeof caseScores.$inferSelect;
export type NewCaseScore = typeof caseScores.$inferInsert;

export const audits = pgTable("audits", {
  id: uuid("id").primaryKey().defaultRandom(),
  userId: uuid("user_id")
    .notNull()
    .references(() => users.id),
  caseId: uuid("case_id")
    .notNull()
    .references(() => cases.id),
  createdAt: timestamp("created_at", { withTimezone: true }).defaultNow(),
  content: text("content").notNull(),
  score: integer("score"),
});

export type Audit = typeof audits.$inferSelect;
export type NewAudit = typeof audits.$inferInsert;

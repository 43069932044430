import React, { useEffect, useState } from "react";
import Button from "./common/Button";
import { Link } from "react-router-dom";
import Input from "./common/Input";
import { useNavigate, useSearchParams } from "react-router-dom";
import axios, { AxiosError } from "axios";
import { useAuth } from "../context/AuthContext";
import { FcGoogle } from "react-icons/fc";
import { ReactComponent as Logo } from "../police-narratives-ai.svg";
import bgImg from "../pnai-bg.jpg";

function Login() {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [errorState, setErrorState] = useState<boolean>(false);
  const navigate = useNavigate();
  const { setIsAuthenticated, setUser } = useAuth();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    // Check authentication status when component mounts
    const checkAuthStatus = async () => {
      try {
        const response = await axios.get("/api/user");
        if (response.data.user) {
          setIsAuthenticated(true);
          setUser(response.data.user);
          if (searchParams.has("redirectto")) {
            navigate(`/${searchParams.get("redirectto")}`);
          } else {
            navigate("/cases");
          }
        }
      } catch (error) {
        // User is not authenticated, stay on login page
        setIsAuthenticated(false);
      }
    };

    checkAuthStatus();
  }, [navigate, setIsAuthenticated, setUser, searchParams]);

  const validateForm = () => {
    let newErrors: {
      email?: string;
      password?: string;
    } = {};

    // Email validation
    if (!email) {
      newErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      newErrors.email = "Email is invalid";
    }

    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).{12,}$/;

    // Password validation
    if (!password) {
      newErrors.password = "Password is required";
    } else if (!passwordRegex.test(password)) {
      newErrors.password =
        "Password must be at least 12 characters long and include a combination of uppercase and lowercase letters, numbers, and symbols";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (validateForm()) {
      try {
        const response = await axios.post("/api/login", { email, password });
        setIsAuthenticated(true);
        setUser(response.data.user);
        if (searchParams.has("redirectto")) {
          navigate(`/${searchParams.get("redirectto")}`);
        } else {
          navigate("/cases");
        }
      } catch (error) {
        if (axios.isAxiosError(error)) {
          setErrorState(true);
          const axiosError = error as AxiosError<{ error: string }>;
          if (axiosError.response) {
            console.error(axiosError.response.data.error);
            console.log(error);
            setErrors({
              overall: "Incorrect login credentials.",
            });
          }
        }
      }
    } else {
      setErrorState(true);
    }
  };

  const handleGoogleLogin = () => {
    window.location.href = `${process.env.REACT_APP_BACKEND_URL}/api/auth/google`;
  };

  return (
    <div className="grid grid-cols-1 md:grid-cols-2">
      <div
        className="hidden md:flex relative items-center justify-center bg-cover bg-center bg-no-repeat"
        style={{ backgroundImage: `url(${bgImg})` }}
      >
        <div className="absolute inset-0 bg-blue-50/70"></div>
        <div className="w-3/5 z-10">
          <Logo className="w-full h-auto" />
        </div>
      </div>
      <div className="flex items-center justify-center min-h-screen bg-gray-100">
        <div className="bg-white p-8 rounded-xl shadow-md w-full max-w-sm">
          <h1 className="text-3xl mb-4 font-semibold">Login</h1>

          <form className="space-y-4" onSubmit={handleSubmit}>
            <div>
              <p className="font-semibold pb-1 text-sm">Email</p>
              <Input
                type="email"
                id="email"
                value={email}
                placeholder="Email address"
                onChange={(e) => {
                  errorState && setErrorState(false);
                  setEmail(e.target.value);
                }}
                className={`input w-full ${errorState && "border-red-500"}`}
              />
              {errors.email && errorState && (
                <p className="mt-1 text-xs text-red-500">{errors.email}</p>
              )}
            </div>
            <div>
              <p className="font-semibold pb-1 text-sm">Password</p>
              <Input
                type="password"
                id="password"
                value={password}
                placeholder="Password"
                onChange={(e) => {
                  errorState && setErrorState(false);
                  setPassword(e.target.value);
                }}
                className={`input w-full max-w-xs ${
                  errorState && "border-red-500"
                }`}
              />
              {errors.password && errorState && (
                <p className="mt-1 text-xs text-red-500">{errors.password}</p>
              )}
              {errors.overall && errorState && (
                <p className="mt-1 text-xs text-red-500">{errors.overall}</p>
              )}
            </div>
            <div>
              <Button className="w-full" variant="primary" type="submit">
                Login
              </Button>
            </div>
          </form>

          <div className="relative my-4">
            <div className="absolute inset-0 flex items-center">
              <div className="w-full border-t border-gray-300" />
            </div>
            <div className="relative flex justify-center text-sm">
              <span className="px-2 bg-white text-gray-500">Or</span>
            </div>
          </div>

          <Button
            onClick={handleGoogleLogin}
            variant="outline"
            className="w-full mb-2"
          >
            <FcGoogle className="w-5 h-5" />
            <span>Continue with Google</span>
          </Button>
          <div className="flex justify-center">
            <p className="font-semibold text-sm text-gray-500">
              Don't have an account? <Link to="/signup">Sign up</Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;

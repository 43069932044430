import { ReportHandler } from "web-vitals";

const reportWebVitals = (onPerfEntry?: ReportHandler) => {
  if (onPerfEntry && onPerfEntry instanceof Function) {
    import("web-vitals").then(({ getCLS, getFID, getFCP, getLCP, getTTFB }) => {
      // Wrap the original onPerfEntry function
      const wrappedOnPerfEntry: ReportHandler = (metric) => {
        // Check if the metric is TTFB and has a negative value
        if (metric.name === "TTFB" && metric.value < 0) {
          // This is likely the ResizeObserver error, so we'll ignore it
          console.log(
            "Suppressed negative TTFB metric, likely due to ResizeObserver error"
          );
          return;
        }
        // For all other metrics, call the original onPerfEntry function
        onPerfEntry(metric);
      };

      // Use the wrapped function instead of the original
      getCLS(wrappedOnPerfEntry);
      getFID(wrappedOnPerfEntry);
      getFCP(wrappedOnPerfEntry);
      getLCP(wrappedOnPerfEntry);
      getTTFB(wrappedOnPerfEntry);
    });
  }
};

// Patch the ResizeObserver to prevent the error
const patchResizeObserver = () => {
  const global = window as any;
  const OriginalResizeObserver = global.ResizeObserver;

  global.ResizeObserver = class PatchedResizeObserver extends (
    OriginalResizeObserver
  ) {
    constructor(callback: ResizeObserverCallback) {
      super((entries: ResizeObserverEntry[], observer: ResizeObserver) => {
        requestAnimationFrame(() => {
          callback(entries, observer);
        });
      });
    }
  };
};

// Apply the patch
patchResizeObserver();

export default reportWebVitals;

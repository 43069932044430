export const toastOptions = {
  duration: 5000,
  style: {
    maxWidth: "320px",
    fontFamily: "Inter",
    color: "#333333",
    padding: "10px 15px",
  },
  success: {
    iconTheme: {
      primary: "#0768fd",
      secondary: "#fff",
    },
  },
  loading: {
    iconTheme: {
      primary: "#0768fd",
      secondary: "#fff",
    },
  },
};

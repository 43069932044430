import { ButtonHTMLAttributes, ReactNode } from "react";
import cn from "classnames";
import Tooltip, { TooltipPosition } from "./Tooltip";

interface IconButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: ReactNode;
  isActive?: boolean;
  description?: string;
  tooltipPosition?: TooltipPosition;
}

export default function IconButton({
  children,
  className,
  disabled,
  isActive,
  description,
  tooltipPosition,
  ...props
}: IconButtonProps) {
  const button = (
    <button
      className={cn(
        className,
        "btn border-0 hover:bg-blue-100 p-2 m-[1px] h-auto min-h-0 shadow-none",
        {
          "bg-blue-100": isActive,
          "bg-gray-200 text-gray-400 pointer-events-none": disabled,
        }
      )}
      {...props}
    >
      {children}
    </button>
  );

  return description ? (
    <Tooltip placement={tooltipPosition} overlay={description}>
      {button}
    </Tooltip>
  ) : (
    button
  );
}

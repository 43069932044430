import { useState, useCallback } from "react";
import { Outlet, useLocation } from "react-router-dom";
import Menu from "./Menu/Menu";
import { CaseScore, Case as CaseType, Suggestion } from "../db/schema";
import CasesLayout from "./CasesLayout";

function Dashboard() {
  const [newCase, setNewCase] = useState<CaseType | null>(null);
  const [selectedCaseId, setSelectedCaseId] = useState<string>("");
  const [caseContent, setCaseContent] = useState<{ data: string } | undefined>(
    undefined
  );
  const [hasCases, setHasCases] = useState<boolean>(true);
  const [caseScore, setCaseScore] = useState<CaseScore | undefined>(undefined);
  const [caseSuggestions, setCaseSuggestions] = useState<
    Suggestion[] | undefined
  >([]);
  const [caseAudioUrl, setCaseAudioUrl] = useState<string>("");

  const location = useLocation();
  const path = location.pathname.split("/")[1];

  const handleNewCase = useCallback(
    (
      createdCase: CaseType,
      score: CaseScore | undefined = undefined,
      suggestions: Suggestion[] = []
    ) => {
      setNewCase(createdCase);
      setSelectedCaseId(createdCase.id);
      setCaseContent(createdCase.content as { data: string });
      setHasCases(true);
      setCaseScore(score);
      setCaseSuggestions(suggestions);
      setCaseAudioUrl(createdCase.audioUrl || "");
    },
    []
  );

  const renderContent = () => {
    if (path === "cases" || path === "trash") {
      return (
        <CasesLayout
          selectedCaseId={selectedCaseId}
          setSelectedCaseId={setSelectedCaseId}
          caseContent={caseContent}
          setCaseContent={setCaseContent}
          hasCases={hasCases}
          setHasCases={setHasCases}
          caseScore={caseScore}
          setCaseScore={setCaseScore}
          caseSuggestions={caseSuggestions}
          setCaseSuggestions={setCaseSuggestions}
          newCase={newCase}
          setCaseAudioUrl={setCaseAudioUrl}
          caseAudioUrl={caseAudioUrl}
        />
      );
    }
    return <Outlet />;
  };

  return (
    <div className="flex h-screen bg-gray-100">
      <Menu onNewCase={handleNewCase} />
      {renderContent()}
    </div>
  );
}

export default Dashboard;

import { useRef, memo } from "react";
import moment from "moment";
import axios from "axios";
import { useAuth } from "../../context/AuthContext";
import toast, { Toaster } from "react-hot-toast";
import { toastOptions } from "../common/toastOptions";
// import Input from "../common/Input";
import Button from "../common/Button";
import MenuItem from "./MenuItem";
import {
  DocumentPlusIcon,
  EllipsisHorizontalIcon,
  MicrophoneIcon,
  ArrowUpTrayIcon,
} from "@heroicons/react/24/outline";
import { Case, CaseScore, CaseStatusType, Suggestion } from "../../db/schema";
import AudioPlayback from "./AudioPlayback";
import { ReactComponent as Logo } from "../../police-narratives-ai.svg";
import { useNavigate, useLocation } from "react-router-dom";
import { Cog8ToothIcon } from "@heroicons/react/24/solid";
import cn from "classnames";

type OnNewCase = (
  newCase: Case,
  score?: CaseScore | undefined,
  suggestions?: Suggestion[]
) => void;

interface MenuProps {
  onNewCase: OnNewCase;
}

const Menu = memo(function ({ onNewCase }: MenuProps) {
  const { user, decrementTrialLogs } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const audioInputRef = useRef<HTMLInputElement>(null);
  const modalRef = useRef<HTMLDialogElement>(null);
  // Loading states
  // const [uploading, setUploading] = useState(false);
  // const [isLoading, setIsLoading] = useState(false);
  // const [audioUploading, setAudioUploading] = useState(false);
  // const [audioRecord, setAudioRecording] = useState(false);

  const handleAvatarClick = () => {
    fileInputRef.current?.click();
  };

  // Avatar upload
  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    console.log(event);
    // const file = event.target.files?.[0];
    // if (!file) return;

    // try {
    //   const data = await s3Client.send(
    //     new PutObjectCommand({
    //       Body: file,
    //       Bucket: process.env.BUCKET,
    //       Key: `${user?.id}-${file.name}`,
    //     })
    //   );
    //   console.log("File uploaded successfully", data);
    // } catch (err) {
    //   console.error("Error uploading file:", err);
    // }
  };

  const handleNewCase = async () => {
    // setIsLoading(true);
    try {
      const response = await axios.post(
        "/api/cases",
        {
          caseNumber: `case1234`,
          incidentDate: new Date().toISOString().split("T")[0],
          incidentTime: new Date().toISOString().split("T")[1].split("Z")[0],
          status: CaseStatusType.enum.active,
          content: {
            time: Date.now(),
            blocks: [
              {
                type: "header",
                data: {
                  text: "New case",
                },
              },
            ],
            version: "2.22.2",
          },
        },
        { withCredentials: true }
      );

      if (response.status === 201) {
        onNewCase(response.data.case, undefined, []);
        console.log("New case created:", response.data.case);
      } else {
        console.error("Failed to create new case");
      }
    } catch (error) {
      console.error("Error creating new case:", error);
    } finally {
      // setIsLoading(false);
    }
  };

  const handleOnRecordAudioClick = () => {
    // setAudioRecording(true);
    modalRef.current?.showModal();
  };

  const handleOnUploadAudioClick = () => {
    audioInputRef.current?.click();
  };

  const handleAudioFileChange = async (
    fileOrEvent: File | Blob | React.ChangeEvent<HTMLInputElement>
  ) => {
    let file: File | Blob;

    if (fileOrEvent instanceof File || fileOrEvent instanceof Blob) {
      file = fileOrEvent;
    } else if (fileOrEvent.target && fileOrEvent.target.files) {
      file = fileOrEvent.target.files[0];
    } else {
      console.error("Invalid input: expected File, Blob, or file input event");
      return;
    }

    if (!file) return;

    const toastId = toast.loading("Preparing to upload audio...");

    try {
      // Get pre-signed URL
      const urlResponse = await axios.post(
        "/api/get-upload-url",
        {
          userId: user?.id,
          fileName:
            file instanceof File
              ? file.name
              : `recorded_audio_${user?.id}_${moment().format(
                  "DD-MM-YYYY"
                )}.wav`,
          fileType: file.type,
        },
        { withCredentials: true }
      );

      const { uploadURL, key } = urlResponse.data;

      // Upload to S3 using the pre-signed URL
      await axios.put(uploadURL, file, {
        headers: {
          "Content-Type": file.type,
        },
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / (progressEvent.total || file.size)
          );
          toast.loading(`Uploading audio: ${percentCompleted}%`, {
            id: toastId,
          });
        },
      });

      toast.loading("Audio uploaded successfully. Transcribing...", {
        id: toastId,
        duration: Infinity,
      });

      // Make a POST request to the new transcription endpoint
      const transcriptionResponse = await axios.post(
        "/api/transcribe",
        { key },
        { withCredentials: true }
      );

      console.log("Transcription response:", transcriptionResponse.data);

      // Create a new case with the transcription result
      const newCaseResponse = await axios.post(
        "/api/cases",
        {
          caseNumber: `case-${Date.now()}`,
          incidentDate: new Date().toISOString().split("T")[0],
          incidentTime: new Date().toISOString().split("T")[1].split("Z")[0],
          status: CaseStatusType.enum.active,
          content: { data: transcriptionResponse.data.content },
          suggestions: transcriptionResponse.data.suggestions,
          score: transcriptionResponse.data.score,
          audioKey: key,
        },
        { withCredentials: true }
      );

      if (newCaseResponse.status === 201) {
        onNewCase(
          newCaseResponse.data.case,
          newCaseResponse.data.score,
          newCaseResponse.data.suggestions
        );
        console.log("New case created from audio:", newCaseResponse.data.case);

        // Optimistically update the trial logs count
        if (user?.subscription_status === "trialing") {
          decrementTrialLogs();
        }

        toast.success("Transcription complete and new case created", {
          id: toastId,
          duration: 5000,
        });
      } else {
        throw new Error("Failed to create new case from audio transcription");
      }

      // Reset the file input after successful upload
      if (audioInputRef.current) {
        audioInputRef.current.value = "";
      }
    } catch (error) {
      console.error("Error processing audio file:", error);
      toast.error("Error processing audio file", { id: toastId });
    }
  };

  const loadAvatar = () => {
    if (user?.avatarUrl) {
      return <img src={user.avatarUrl} alt="" />;
    } else if (user?.name) {
      return (
        <div className="text-white font-semibold text-lg absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
          {`${user.name.split(" ")[0][0]}${
            user.name.split(" ")[user.name.split(" ").length - 1]?.[0] || ""
          }`}
        </div>
      );
    }
  };

  return (
    <div className="w-64 bg-gray-50 border-r-2 flex flex-col h-screen">
      <AudioPlayback
        modalRef={modalRef}
        handleAudioFileChange={handleAudioFileChange}
      />
      <div className="p-6">
        <div className="mb-4">
          <Logo className="w-full h-auto" />
        </div>
        <div className="grid gap-y-2">
          {/* <Input
              type="search"
              id="search"
              // value={email}
              placeholder={`Search`}
              // onChange={(e) => {
              //   errorState && setErrorState(false);
              //   setEmail(e.target.value);
              // }}
              className={`input w-full input-ghost mt-2`}
            /> */}
          <div className="grid grid-cols-10 gap-x-2">
            <div className="col-span-7">
              <Button
                className="btn-primary w-full"
                onClick={handleNewCase}
                variant="primary"
                // disabled={isLoading}
              >
                <div className="flex items-center pl-2">
                  <DocumentPlusIcon className="size-6 mr-1" />
                  <p>New Case</p>
                </div>
              </Button>
            </div>
            <div className="col-span-3 dropdown dropdown-end">
              <Button
                className="btn btn-active border-0 w-full"
                tabIndex={0}
                variant="secondary"
              >
                {" "}
                <EllipsisHorizontalIcon className="size-6" />
              </Button>
              <ul
                tabIndex={0}
                className="dropdown-content menu bg-base-100 rounded-lg z-[1] w-52 p-2 shadow"
              >
                <li>
                  <p
                    onClick={handleOnRecordAudioClick}
                    className="hover:bg-gray-100"
                  >
                    <MicrophoneIcon className="size-4" />
                    Record Audio
                  </p>
                </li>
                <li onClick={handleOnUploadAudioClick}>
                  <p className="hover:bg-gray-100">
                    <ArrowUpTrayIcon className="size-4" />
                    Upload Audio
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <input
          type="file"
          ref={audioInputRef}
          onChange={handleAudioFileChange}
          accept="audio/*"
          className="hidden"
        />
        <div className="grid gap-y-1 mt-6">
          <MenuItem page="cases">
            <p>Cases</p>
          </MenuItem>
          <MenuItem page="trash">
            <p>Trash</p>
          </MenuItem>
        </div>
      </div>
      {/* User Badge */}
      <div
        className="border-t px-4 py-2 mt-auto hover:cursor-pointer group" // Add group class here
        onClick={() => navigate("/settings")}
      >
        <div className="grid grid-cols-12 gap-x-3 items-center">
          <div
            className="avatar col-span-3 cursor-pointer flex"
            // onClick={handleAvatarClick}
          >
            <div className="rounded-full overflow-hidden relative bg-blue-500 flex items-center justify-center aspect-square w-full min-w-[2.5rem] min-h-[2.5rem]">
              {loadAvatar()}
            </div>
          </div>
          <input
            type="file"
            ref={fileInputRef}
            onChange={handleFileChange}
            accept="image/*"
            className="hidden"
          />
          <div className="text col-span-7 flex items-center">
            <div className="flex flex-col justify-between h-full w-full py-2">
              <p className="font-bold text-sm h-auto">
                {(user?.name || "Name here").split(" ").map((word, i) => (
                  <>
                    <span
                      key={i}
                      className="h-auto inline-block max-w-[140px] truncate leading-none mb-[-2px]"
                    >
                      {word}
                    </span>
                    {i < 1 && " "}
                  </>
                ))}
              </p>
              <p className="text-gray-500 text-sm">{`Badge #${user?.badgeID}`}</p>
            </div>
          </div>
          <div className="col-span-2 flex items-center">
            <Cog8ToothIcon
              className={cn(
                "size-6 text-gray-500 opacity-50 group-hover:opacity-80 transition-opacity",
                location.pathname.startsWith("/settings") &&
                  "!text-blue-500 !opacity-100 transition-opacity"
              )}
            />
          </div>
        </div>
      </div>
      <Toaster position="bottom-center" toastOptions={toastOptions} />
    </div>
  );
});

export default Menu;

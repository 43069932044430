import React, { RefObject, useRef, useState, useEffect } from "react";
import moment from "moment";
import cn from "classnames";
import Button from "../common/Button";
import { XMarkIcon } from "@heroicons/react/24/outline";
import {
  StopIcon,
  PauseCircleIcon,
  PauseIcon,
  PlayIcon,
  PlayCircleIcon,
  SpeakerWaveIcon,
  SpeakerXMarkIcon,
  BackwardIcon,
  ForwardIcon,
  TrashIcon,
  ArrowUpTrayIcon,
  MicrophoneIcon,
} from "@heroicons/react/24/solid";
import AudioPlayer from "react-h5-audio-player";
import IconButton from "../common/IconButton";
import "react-h5-audio-player/lib/styles.css";
import "./AudioPlayback.css";

interface FormattedTimerProps {
  timer: number;
  status: Status;
}

interface AudioPlaybackProps {
  modalRef: RefObject<HTMLDialogElement>;
  handleAudioFileChange: (
    fileOrEvent: File | Blob | React.ChangeEvent<HTMLInputElement>
  ) => Promise<void>;
}

type Status = "idle" | "recording" | "paused";

interface AudioInputDevice {
  deviceId: string;
  label: string;
}

function FormattedTimer({ timer, status }: FormattedTimerProps) {
  const recording = status === "recording" || status === "paused";
  return (
    <div
      className={cn(
        "transition-all duration-300 ease-in-out",
        recording
          ? "opacity-100 max-h-20"
          : "opacity-0 max-h-0 overflow-hidden",
        "flex flex-col w-full items-center"
      )}
    >
      <div className="flex items-center justify-center">
        <p
          className={cn(
            status === "recording" && "animate-flashing",
            "text-2xl text-red-600 mr-1 mt-[-2px]"
          )}
        >
          &#9679;
        </p>
        <code>{moment.utc(timer * 1000).format("mm:ss")}</code>
      </div>
    </div>
  );
}
interface AudioInputDevice {
  deviceId: string;
  label: string;
}

function AudioInputSelector({
  onDeviceChange,
  selectedDeviceId,
  hasPermission,
  devices,
}: {
  onDeviceChange: (deviceId: string) => void;
  selectedDeviceId: string;
  hasPermission: boolean;
  devices: AudioInputDevice[];
}) {
  if (!hasPermission) {
    return (
      <div className="flex items-center form-control w-full mb-16 text-center">
        <div className="max-w-s">
          <code>Grant microphone access to start recording</code>
        </div>
      </div>
    );
  }

  return (
    <div className="flex items-center form-control w-full mb-16">
      <select
        className="btn border border-black-400 select max-w-xs focus:outline-offset-0 focus:outline-0 focus:border-1"
        onChange={(e) => onDeviceChange(e.target.value)}
        value={selectedDeviceId}
      >
        <option disabled value="">
          Select your microphone
        </option>
        {devices.map((device) => (
          <option key={device.deviceId} value={device.deviceId}>
            {device.label}
          </option>
        ))}
      </select>
    </div>
  );
}

export default function AudioPlayback({
  modalRef,
  handleAudioFileChange,
}: AudioPlaybackProps) {
  const [status, setStatus] = useState<Status>("idle");
  const [recorded, setRecorded] = useState<boolean>(false);
  const [audioResult, setAudioResult] = useState<string | null>(null);
  const [timer, setTimer] = useState<number>(0);
  const [selectedDeviceId, setSelectedDeviceId] = useState<string>("");
  const [hasPermission, setHasPermission] = useState<boolean>(false);
  const [devices, setDevices] = useState<AudioInputDevice[]>([]);
  const closeButtonRef = useRef<HTMLButtonElement | null>(null);
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const audioChunksRef = useRef<Blob[]>([]);
  const timerIntervalRef = useRef<NodeJS.Timeout | null>(null);

  // Check initial permission status when modal opens

  const enumerateDevices = async () => {
    try {
      const allDevices = await navigator.mediaDevices.enumerateDevices();
      const audioInputDevices = allDevices
        .filter((device) => device.kind === "audioinput")
        .map((device) => ({
          deviceId: device.deviceId,
          label:
            device.label || `Microphone (${device.deviceId.slice(0, 5)}...)`,
        }));
      setDevices(audioInputDevices);

      if (audioInputDevices.length > 0) {
        const defaultDevice =
          audioInputDevices.find((device) => device.deviceId === "default") ||
          audioInputDevices[0];
        setSelectedDeviceId(defaultDevice.deviceId);
      }
    } catch (error) {
      console.error("Error enumerating devices:", error);
    }
  };

  const handleDeviceChange = (deviceId: string) => {
    setSelectedDeviceId(deviceId);
  };

  const requestPermissionAndGetDevices = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        audio: true,
      });
      stream.getTracks().forEach((track) => track.stop());
      setHasPermission(true);

      const allDevices = await navigator.mediaDevices.enumerateDevices();
      const audioInputDevices = allDevices
        .filter((device) => device.kind === "audioinput")
        .map((device) => ({
          deviceId: device.deviceId,
          label:
            device.label || `Microphone (${device.deviceId.slice(0, 5)}...)`,
        }));
      setDevices(audioInputDevices);

      if (audioInputDevices.length > 0) {
        const defaultDevice =
          audioInputDevices.find((device) => device.deviceId === "default") ||
          audioInputDevices[0];
        setSelectedDeviceId(defaultDevice.deviceId);
      }
    } catch (error) {
      console.error(
        "Error requesting permission or enumerating devices:",
        error
      );
      setHasPermission(false);
    }
  };

  const startRecording = async () => {
    // if (!hasPermission) {
    //   await requestPermissionAndGetDevices();
    // }

    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        audio: {
          deviceId: selectedDeviceId ? { exact: selectedDeviceId } : undefined,
        },
      });

      const mediaRecorder = new MediaRecorder(stream);
      mediaRecorderRef.current = mediaRecorder;
      audioChunksRef.current = [];

      mediaRecorder.ondataavailable = (event) => {
        audioChunksRef.current.push(event.data);
      };

      mediaRecorder.onstop = () => {
        const audioBlob = new Blob(audioChunksRef.current, {
          type: "audio/wav",
        });
        const audioUrl = URL.createObjectURL(audioBlob);
        setAudioResult(audioUrl);
        setRecorded(true);
      };

      mediaRecorder.start();
      setStatus("recording");
      setTimer(0);
      timerIntervalRef.current = setInterval(() => {
        setTimer((prevTimer) => prevTimer + 1);
      }, 1000);
    } catch (error) {
      console.error("Error starting recording:", error);
    }
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current && status === "recording") {
      mediaRecorderRef.current.stop();
      setStatus("idle");
      if (timerIntervalRef.current) {
        clearInterval(timerIntervalRef.current);
      }
    }
  };

  const pauseRecording = () => {
    if (mediaRecorderRef.current && status === "recording") {
      mediaRecorderRef.current.pause();
      setStatus("paused");
      if (timerIntervalRef.current) {
        clearInterval(timerIntervalRef.current);
      }
    }
  };

  const resumeRecording = () => {
    if (mediaRecorderRef.current && status === "paused") {
      mediaRecorderRef.current.resume();
      setStatus("recording");
      timerIntervalRef.current = setInterval(() => {
        setTimer((prevTimer) => prevTimer + 1);
      }, 1000);
    }
  };

  const resetState = () => {
    setStatus("idle");
    setRecorded(false);
    setAudioResult(null);
    setTimer(0);
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stream
        .getTracks()
        .forEach((track) => track.stop());
      mediaRecorderRef.current = null;
    }
    audioChunksRef.current = [];
    if (timerIntervalRef.current) {
      clearInterval(timerIntervalRef.current);
      timerIntervalRef.current = null;
    }
  };

  const uploadAudio = async () => {
    if (audioResult) {
      try {
        const response = await fetch(audioResult);
        const wavBlob = await response.blob();
        // stop recording
        if (mediaRecorderRef.current && timerIntervalRef.current) {
          mediaRecorderRef.current.stop();
          clearInterval(timerIntervalRef.current);
        }
        closeButtonRef.current?.click();
        await handleAudioFileChange(wavBlob);
        resetState(); // Reset state after successful upload
      } catch (error) {
        console.error("Error fetching the blob:", error);
      }
    }
  };

  // Add cleanup on modal close
  useEffect(() => {
    const handleModalClose = () => {
      resetState();
    };

    const modal = modalRef.current;
    modal?.addEventListener("close", handleModalClose);

    return () => {
      modal?.removeEventListener("close", handleModalClose);
    };
  }, [modalRef]);

  useEffect(() => {
    return () => {
      if (timerIntervalRef.current) {
        clearInterval(timerIntervalRef.current);
      }
    };
  }, []);

  useEffect(() => {
    const checkPermissionStatus = async () => {
      try {
        const permissionStatus = await navigator.permissions.query({
          name: "microphone" as PermissionName,
        });
        setHasPermission(permissionStatus.state === "granted");

        // Listen for permission changes
        permissionStatus.addEventListener("change", () => {
          setHasPermission(permissionStatus.state === "granted");

          // If permission is revoked, stop recording if in progress
          if (
            permissionStatus.state !== "granted" &&
            mediaRecorderRef.current
          ) {
            stopRecording();
          }
        });

        // If permission is already granted, enumerate devices
        if (permissionStatus.state === "granted") {
          await enumerateDevices();
        }
      } catch (error) {
        console.error("Error checking permission:", error);
        setHasPermission(false);
      }
    };

    checkPermissionStatus();
  }, []);

  return (
    <dialog id="my_modal_1" className="modal" ref={modalRef}>
      <div className={`modal-box ${status}`}>
        <form className="flex justify-end" method="dialog">
          <Button
            className="btn border-0 shadow-none right-0 p-0 !w-6 !h-6"
            ref={closeButtonRef}
            variant="clean"
          >
            <XMarkIcon className="size-6" />
          </Button>
        </form>
        <AudioInputSelector
          onDeviceChange={handleDeviceChange}
          selectedDeviceId={selectedDeviceId}
          hasPermission={hasPermission}
          devices={devices}
        />
        <AudioPlayer
          className={
            !recorded || status === "recording" || status === "paused"
              ? "disabled"
              : ""
          }
          loop={false}
          autoPlay
          src={audioResult || undefined}
          onPlay={(e) => console.log("onPlay")}
          showFilledVolume
          defaultCurrentTime="00:00"
          defaultDuration="00:00"
          timeFormat="mm:ss"
          customIcons={{
            play: <PlayCircleIcon className="text-black-400" />,
            volume: <SpeakerWaveIcon className="text-black-400" />,
            volumeMute: <SpeakerXMarkIcon className="text-black-400" />,
            pause: <PauseCircleIcon className="text-black-400" />,
            rewind: <BackwardIcon className="text-black-400" />,
            forward: <ForwardIcon className="text-black-400" />,
          }}
        />
        <FormattedTimer timer={timer} status={status} />
        <div className="flex items-center justify-center">
          {status === "idle" && !recorded && !hasPermission && (
            <IconButton
              className="p-4"
              onClick={requestPermissionAndGetDevices}
            >
              <div className="flex items-center">
                <MicrophoneIcon className="size-4" />
                <p className="ml-1">Grant Recording Permission</p>
              </div>
            </IconButton>
          )}
          {status === "idle" && !recorded && hasPermission && (
            <IconButton className="h-[32px] p-4" onClick={startRecording}>
              <div className="relative inline-block">
                <div className="flex items-center justify-center">
                  <p className="text-2xl text-red-600 absolute left-0 mt-[-2px]">
                    &#9679;
                  </p>
                  <p className="ml-5">Record</p>
                </div>
              </div>
            </IconButton>
          )}
          {status === "idle" && recorded && (
            <>
              <IconButton className="h-[32px] p-4" onClick={startRecording}>
                <div className="relative inline-block">
                  <div className="flex items-center justify-center">
                    <TrashIcon className="size-4" />
                    <p>/</p>
                    <p className="text-2xl text-red-600 absolute left-0 mt-[-2px] ml-[22px]">
                      &#9679;
                    </p>
                    <p className="ml-5">Re-record</p>
                  </div>
                </div>
              </IconButton>
              <IconButton
                className="bg-blue-500 text-white hover:bg-blue-600 p-4"
                onClick={uploadAudio}
              >
                <div className="flex items-center">
                  <ArrowUpTrayIcon className="size-4" />
                  <p className="ml-1">Upload</p>
                </div>
              </IconButton>
            </>
          )}
          {status === "recording" && (
            <>
              <IconButton className="p-4" onClick={pauseRecording}>
                <div className="flex items-center">
                  <PauseIcon className="size-4" />
                  <p className="ml-1">Pause</p>
                </div>
              </IconButton>
              <IconButton className="p-4" onClick={stopRecording}>
                <div className="flex items-center">
                  <StopIcon className="size-4" />
                  <p className="ml-1">Stop</p>
                </div>
              </IconButton>
            </>
          )}
          {status === "paused" && (
            <>
              <IconButton className="p-4" onClick={resumeRecording}>
                <div className="flex items-center">
                  <PlayIcon className="size-4 text-green-600" />
                  <p className="ml-1">Resume</p>
                </div>
              </IconButton>
              <IconButton className="p-4" onClick={stopRecording}>
                <div className="flex items-center">
                  <StopIcon className="size-4" />
                  <p className="ml-1">Stop</p>
                </div>
              </IconButton>
            </>
          )}
        </div>
      </div>
    </dialog>
  );
}

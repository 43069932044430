import React, {
  useEffect,
  useMemo,
  useRef,
  useState,
  useLayoutEffect,
  useCallback,
} from "react";
import { ChevronRightIcon, SparklesIcon } from "@heroicons/react/24/outline";
import EditorMenuBar from "./Editor/EditorMenuBar";
import { useEditor, EditorContent } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import Placeholder from "@tiptap/extension-placeholder";
import Underline from "@tiptap/extension-underline";
import { Color } from "@tiptap/extension-color";
import TextStyle from "@tiptap/extension-text-style";
import Highlight from "@tiptap/extension-highlight";
import Table from "@tiptap/extension-table";
import TableCell from "@tiptap/extension-table-cell";
import TableHeader from "@tiptap/extension-table-header";
import TableRow from "@tiptap/extension-table-row";
import IndentHandler from "./Editor/IndentHandler";
import Suggestions from "./Editor/Suggestions";
import cn from "classnames";
import { CaseScore, Suggestion } from "../db/schema";

interface TiptapEditorProps {
  onChange: (data: { content: { data: string }; caseId?: string }) => void;
  caseId: string | undefined;
  initialContent: string;
  page: string;
  caseSuggestions?: Suggestion[];
  caseScore?: CaseScore;
  caseAudioUrl?: string;
}

const TiptapEditor: React.FC<TiptapEditorProps> = ({
  onChange,
  caseId,
  initialContent,
  page,
  caseSuggestions,
  caseScore,
  caseAudioUrl,
}) => {
  const [minimized, setMinimized] = useState<boolean>(false);
  const notTrashPage = page !== "trash";
  const isUserEditing = useRef(false);
  const lastSavedContent = useRef(initialContent);

  const extensions = useMemo(
    () => [
      Color,
      Highlight.configure({ multicolor: true }),
      IndentHandler,
      Placeholder.configure({
        placeholder: ({ node }) => {
          if (node.type.name === "heading" && node.attrs.level === 1) {
            return "Case Title";
          }
          return "Start writing your case narrative here";
        },
      }),
      StarterKit,
      TextStyle,
      Table.configure({
        resizable: true,
        lastColumnResizable: true,
        allowTableNodeSelection: true,
      }),
      TableRow.extend({
        content: "(tableHeader)*",
      }),
      TableHeader,
      TableCell,
      Underline,
    ],
    []
  );

  const handleUpdate = useCallback(
    (editor) => {
      const html = editor.getHTML();
      if (html !== lastSavedContent.current) {
        onChange({
          content: { data: html },
          caseId,
        });
        lastSavedContent.current = html;
      }
    },
    [onChange, caseId]
  );

  const editor = useEditor({
    extensions,
    content: initialContent,
    editorProps: {
      attributes: {
        class: "prose max-w-none mt-8",
      },
    },
    onUpdate: ({ editor }) => {
      if (isUserEditing.current) {
        handleUpdate(editor);
      }
    },
    onFocus: () => {
      isUserEditing.current = true;
    },
    onBlur: () => {
      isUserEditing.current = false;
    },
  });

  useLayoutEffect(() => {
    if (editor) {
      if (!initialContent) {
        // If there's no content, set up a new document with h1
        editor.commands.clearContent();
        editor.commands.setNode("heading", { level: 1 });
      } else if (initialContent !== editor.getHTML()) {
        editor.commands.setContent(initialContent);
      }
      lastSavedContent.current = editor.getHTML();
    }
  }, [editor, initialContent]);

  useEffect(() => {
    if (editor) {
      editor.setEditable(page !== "trash");
    }
  }, [editor, page]);

  useEffect(() => {
    return () => {
      if (editor) {
        editor.destroy();
      }
    };
  }, [editor]);

  return (
    <>
      <EditorMenuBar
        editor={editor}
        disabled={page === "trash"}
        caseId={caseId}
        score={caseScore?.score || null}
      />
      {/* {page === "trash" && (
        <div className="text-center bg-blue-50 text-blue-600 p-2">
          <p className="">Read-only</p>
        </div>
      )} */}
      <div className="flex h-screen relative">
        <div
          className={cn(
            minimized ? "w-full" : "w-3/4 3xl:w-5/6",
            "overflow-y-auto border-r-2 transition-all duration-300 ease-in-out flex-grow bg-white",
            notTrashPage && "mb-[56px]"
          )}
        >
          <div className="flex flex-col h-full">
            <div className="flex-1 bg-white flex justify-center">
              <div className="flex-1 p-6 flex flex-col px-24 max-w-6xl w-full h-[120vh]">
                <div className="flex-grow">
                  <div className="editor [&_.ProseMirror_ul_>_li_>_p]:mb-0 [&_.ProseMirror_ul_>_li_>_p]:mt-0 [&_.ProseMirror_ol_>_li_>_p]:mb-0 [&_.ProseMirror_ol_>_li_>_p]:mt-0">
                    <EditorContent
                      editor={editor}
                      className="max-w-none mt-8"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {notTrashPage && (
          <>
            <div
              className={cn(
                "flex absolute z-20 h-48 transition-all duration-300 ease-in-out cursor-pointer",
                minimized
                  ? "right-[-20px] hover:right-0"
                  : "right-[25%] 3xl:right-[16.666%]",
                "top-8"
              )}
              onClick={() => setMinimized(!minimized)}
            >
              <div
                className={cn(
                  "absolute right-3 rounded-l-3xl hover:rounded-full top-[-12px] bottom-0 z-20 bg-gray-100 h-12 w-12 corner transition-all duration-250 ease-in-out",
                  !minimized && "!rounded-full"
                )}
              >
                {minimized ? (
                  <SparklesIcon className="size-6 text-blue-500 ml-[9px] mt-3" />
                ) : (
                  <ChevronRightIcon className="size-6 text-blue-500 ml-[12px] mt-[12px]" />
                )}
              </div>
            </div>
            <div
              className={cn(
                "transition-all duration-300 ease-in-out",
                "overflow-y-auto mb-[56px]",
                minimized
                  ? "w-0 opacity-0 invisible"
                  : "w-1/4 3xl:w-1/6 opacity-100 visible"
              )}
            >
              <Suggestions
                caseSuggestions={caseSuggestions}
                caseScore={caseScore}
                caseId={caseId}
                caseAudioUrl={caseAudioUrl}
                editorContent={editor
                  ?.getText()
                  .replace(/\n{3,}/g, "\n\n")
                  .trim()}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
};

interface EditorProps {
  caseContent?: { data: string };
  onChange?: ({
    content,
    caseId,
  }: {
    content: { data: string };
    caseId?: string;
  }) => void;
  caseId?: string;
  hasCases?: boolean;
  page: string;
  caseSuggestions?: Suggestion[];
  caseScore?: CaseScore;
  caseAudioUrl?: string;
}

function Editor({
  caseContent,
  onChange,
  hasCases,
  caseId,
  page,
  caseSuggestions,
  caseScore,
  caseAudioUrl,
}: EditorProps) {
  const initialContent = caseContent?.data || "";
  return (
    <>
      {hasCases ? (
        <div className="flex flex-col h-full overflow-hidden">
          <TiptapEditor
            onChange={onChange!}
            caseId={caseId}
            initialContent={initialContent}
            page={page}
            caseSuggestions={caseSuggestions}
            caseScore={caseScore}
            caseAudioUrl={caseAudioUrl}
          />
        </div>
      ) : (
        <div className="flex-1 flex items-center justify-center bg-white pt-6">
          <p className="text-xl text-gray-500">
            Create a new case to start reporting
          </p>
        </div>
      )}
    </>
  );
}

export default Editor;

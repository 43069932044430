const PrivacyPolicy = () => {
  return (
    <div className="m-6">
      <h1 className="text-3xl font-bold">PRIVACY POLICY</h1>
      <p className="text-sm text-gray-600 mb-8">
        Last updated November 11, 2024
      </p>

      <div className="mb-8">
        <p className="mb-4">
          This Privacy Notice for MILLIONHARI LLC (doing business as
          PoliceNarratives.ai) ("we," "us," or "our"), describes how and why we
          might access, collect, store, use, and/or share ("process") your
          personal information when you use our services ("Services"), including
          when you:
        </p>

        <ul className="list-disc pl-8 mb-4">
          <li>
            Visit our website at{" "}
            <a
              href="https://policenarratives.ai/"
              className="text-blue-600 hover:underline"
            >
              https://policenarratives.ai/
            </a>
            , or any website of ours that links to this Privacy Notice
          </li>
          <li>
            Download and use our mobile application (Police Narratives AI), or
            any other application of ours that links to this Privacy Notice
          </li>
          <li>
            Use Police Narratives AI. Software designed to generate and/or
            improve police narrative reports by text or audio input.
          </li>
          <li>
            Engage with us in other related ways, including any sales,
            marketing, or events
          </li>
        </ul>

        <p className="mb-4">
          <strong>Questions or concerns?</strong> Reading this Privacy Notice
          will help you understand your privacy rights and choices. We are
          responsible for making decisions about how your personal information
          is processed. If you do not agree with our policies and practices,
          please do not use our Services. If you still have any questions or
          concerns, please contact us at support@policenarratives.ai.
        </p>
      </div>

      <div className="mb-8">
        <h2 className="text-2xl font-bold mb-4">SUMMARY OF KEY POINTS</h2>
        <p className="italic mb-4">
          <strong>
            This summary provides key points from our Privacy Notice, but you
            can find out more details about any of these topics by clicking the
            link following each key point or by using our table of contents
            below to find the section you are looking for.
          </strong>
        </p>

        <p className="mb-4">
          <strong>What personal information do we process?</strong> When you
          visit, use, or navigate our Services, we may process personal
          information depending on how you interact with us and the Services,
          the choices you make, and the products and features you use.
        </p>

        <p className="mb-4">
          <strong>Do we process any sensitive personal information?</strong>{" "}
          Some of the information may be considered "special" or "sensitive" in
          certain jurisdictions, for example your racial or ethnic origins,
          sexual orientation, and religious beliefs. We may process sensitive
          personal information when necessary with your consent or as otherwise
          permitted by applicable law.
        </p>

        <p className="mb-4">
          <strong>Do we collect any information from third parties?</strong> We
          do not collect any information from third parties.
        </p>

        <p className="mb-4">
          <strong>How do we process your information?</strong> We process your
          information to provide, improve, and administer our Services,
          communicate with you, for security and fraud prevention, and to comply
          with law. We may also process your information for other purposes with
          your consent.
        </p>

        <p className="mb-4">
          <strong>
            In what situations and with which parties do we share personal
            information?
          </strong>{" "}
          We may share information in specific situations and with specific
          third parties.
        </p>

        <p className="mb-4">
          <strong>How do we keep your information safe?</strong> We have
          adequate organizational and technical processes and procedures in
          place to protect your personal information. However, no electronic
          transmission over the internet or information storage technology can
          be guaranteed to be 100% secure.
        </p>

        <p className="mb-4">
          <strong>What are your rights?</strong> Depending on where you are
          located geographically, the applicable privacy law may mean you have
          certain rights regarding your personal information.
        </p>

        <p className="mb-4">
          <strong>How do you exercise your rights?</strong> Depending on where
          you are located geographically, the applicable privacy law may mean
          you have certain rights regarding your personal information.
          <strong>How do you exercise your rights?</strong> The easiest way to
          exercise your rights is by submitting a 
          <a
            href="https://app.termly.io/notify/0f0ebd47-a08e-4053-8438-964279051be5"
            rel="noopener noreferrer"
            target="_blank"
          >
            data subject access request
          </a>
          , or by contacting us. We will consider and act upon any request in
          accordance with applicable data protection laws.
        </p>
      </div>

      <div className="mb-8">
        <h2 className="text-2xl font-bold mb-4">TABLE OF CONTENTS</h2>
        <ol className="list-decimal pl-8 mb-4">
          <li>
            <a href="#infocollect" className="text-blue-600 hover:underline">
              WHAT INFORMATION DO WE COLLECT?
            </a>
          </li>
          <li>
            <a href="#infouse" className="text-blue-600 hover:underline">
              HOW DO WE PROCESS YOUR INFORMATION?
            </a>
          </li>
          <li>
            <a href="#whoshare" className="text-blue-600 hover:underline">
              WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
            </a>
          </li>
          <li>
            <a href="#cookies" className="text-blue-600 hover:underline">
              DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
            </a>
          </li>
          <li>
            <a href="#ai" className="text-blue-600 hover:underline">
              DO WE OFFER ARTIFICIAL INTELLIGENCE-BASED PRODUCTS?
            </a>
          </li>
          <li>
            <a href="#sociallogins" className="text-blue-600 hover:underline">
              HOW DO WE HANDLE YOUR SOCIAL LOGINS?
            </a>
          </li>
          <li>
            <a href="#inforetain" className="text-blue-600 hover:underline">
              HOW LONG DO WE KEEP YOUR INFORMATION?
            </a>
          </li>
          <li>
            <a href="#infosafe" className="text-blue-600 hover:underline">
              HOW DO WE KEEP YOUR INFORMATION SAFE?
            </a>
          </li>
          <li>
            <a href="#infominors" className="text-blue-600 hover:underline">
              DO WE COLLECT INFORMATION FROM MINORS?
            </a>
          </li>
          <li>
            <a href="#privacyrights" className="text-blue-600 hover:underline">
              WHAT ARE YOUR PRIVACY RIGHTS?
            </a>
          </li>
          <li>
            <a href="#DNT" className="text-blue-600 hover:underline">
              CONTROLS FOR DO-NOT-TRACK FEATURES
            </a>
          </li>
          <li>
            <a href="#uslaws" className="text-blue-600 hover:underline">
              DO UNITED STATES RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
            </a>
          </li>
          <li>
            <a href="#policyupdates" className="text-blue-600 hover:underline">
              DO WE MAKE UPDATES TO THIS NOTICE?
            </a>
          </li>
          <li>
            <a href="#contact" className="text-blue-600 hover:underline">
              HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
            </a>
          </li>
          <li>
            <a href="#request" className="text-blue-600 hover:underline">
              HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM
              YOU?
            </a>
          </li>
        </ol>
      </div>

      <div id="infocollect" className="mb-8">
        <h2 className="text-2xl font-bold mb-4">
          1. WHAT INFORMATION DO WE COLLECT?
        </h2>

        <h3 className="text-xl font-bold mb-4">
          Personal information you disclose to us
        </h3>

        <p className="mb-4">
          <em>
            <strong>In Short:</strong> We collect personal information that you
            provide to us.
          </em>
        </p>

        <p className="mb-4">
          We collect personal information that you voluntarily provide to us
          when you register on the Services, express an interest in obtaining
          information about us or our products and Services, when you
          participate in activities on the Services, or otherwise when you
          contact us.
        </p>

        <p className="mb-4">
          <strong>Personal Information Provided by You.</strong> The personal
          information that we collect depends on the context of your
          interactions with us and the Services, the choices you make, and the
          products and features you use. The personal information we collect may
          include the following:
        </p>

        <ul className="list-disc pl-8 mb-4">
          <li>names</li>
          <li>email addresses</li>
          <li>passwords</li>
        </ul>

        <p className="mb-4">
          <strong>Sensitive Information.</strong> When necessary, with your
          consent or as otherwise permitted by applicable law, we process the
          following categories of sensitive information:
        </p>

        <ul className="list-disc pl-8 mb-4">
          <li>information revealing race or ethnic origin</li>
        </ul>

        <p className="mb-4">
          <strong>Payment Data.</strong> We may collect data necessary to
          process your payment if you make purchases, such as your payment
          instrument number, and the security code associated with your payment
          instrument. All payment data is handled and stored by Stripe, Apple,
          and Google.
        </p>

        <p className="mb-4">
          <strong>Social Media Login Data.</strong> We may provide you with the
          option to register with us using your existing social media account
          details, like your Facebook, X, or other social media account. If you
          choose to register in this way, we will collect certain profile
          information about you from the social media provider.
        </p>

        <p className="mb-4">
          <strong>Application Data.</strong> If you use our application(s), we
          also may collect the following information if you choose to provide us
          with access or permission:
        </p>

        <ul className="list-disc pl-8 mb-4">
          <li>
            <em>Geolocation Information.</em> We may request access or
            permission to track location-based information from your mobile
            device, either continuously or while you are using our mobile
            application(s), to provide certain location-based services.
          </li>
          <li>
            <em>Mobile Device Access.</em> We may request access or permission
            to certain features from your mobile device, including your mobile
            device's microphone, and other features.
          </li>
          <li>
            <em>Mobile Device Data.</em> We automatically collect device
            information (such as your mobile device ID, model, and
            manufacturer), operating system, version information and system
            configuration information, device and application identification
            numbers, browser type and version, hardware model Internet service
            provider and/or mobile carrier, and Internet Protocol (IP) address
            (or proxy server).
          </li>
          <li>
            <em>Push Notifications.</em> We may request to send you push
            notifications regarding your account or certain features of the
            application(s).
          </li>
        </ul>

        <p className="mb-4">
          All personal information that you provide to us must be true,
          complete, and accurate, and you must notify us of any changes to such
          personal information.
        </p>

        <h3 className="text-xl font-bold mb-4">
          Information automatically collected
        </h3>

        <p className="mb-4">
          <em>
            <strong>In Short:</strong> Some information — such as your Internet
            Protocol (IP) address and/or browser and device characteristics — is
            collected automatically when you visit our Services.
          </em>
        </p>

        <p className="mb-4">
          We automatically collect certain information when you visit, use, or
          navigate the Services. This information does not reveal your specific
          identity (like your name or contact information) but may include
          device and usage information, such as your IP address, browser and
          device characteristics, operating system, language preferences,
          referring URLs, device name, country, location, information about how
          and when you use our Services, and other technical information. This
          information is primarily needed to maintain the security and operation
          of our Services, and for our internal analytics and reporting
          purposes.
        </p>

        <p className="mb-4">The information we collect includes:</p>

        <ul className="list-disc pl-8 mb-4">
          <li>
            <em>Log and Usage Data.</em> Log and usage data is service-related,
            diagnostic, usage, and performance information our servers
            automatically collect when you access or use our Services and which
            we record in log files.
          </li>
          <li>
            <em>Device Data.</em> We collect device data such as information
            about your computer, phone, tablet, or other device you use to
            access the Services.
          </li>
          <li>
            <em>Location Data.</em> We collect location data such as information
            about your device's location, which can be either precise or
            imprecise.
          </li>
        </ul>
      </div>

      <div id="infouse" className="mb-8">
        <h2 className="text-2xl font-bold mb-4">
          2. HOW DO WE PROCESS YOUR INFORMATION?
        </h2>

        <p className="mb-4">
          <em>
            <strong>In Short:</strong> We process your information to provide,
            improve, and administer our Services, communicate with you, for
            security and fraud prevention, and to comply with law. We may also
            process your information for other purposes with your consent.
          </em>
        </p>

        <p className="mb-4">
          <strong>
            We process your personal information for a variety of reasons,
            depending on how you interact with our Services, including:
          </strong>
        </p>

        <ul className="list-disc pl-8 mb-4">
          <li>
            <strong>
              To facilitate account creation and authentication and otherwise
              manage user accounts.
            </strong>{" "}
            We may process your information so you can create and log in to your
            account, as well as keep your account in working order.
          </li>
          <li>
            <strong>
              To deliver and facilitate delivery of services to the user.
            </strong>{" "}
            We may process your information to provide you with the requested
            service.
          </li>
          <li>
            <strong>
              To respond to user inquiries/offer support to users.
            </strong>{" "}
            We may process your information to respond to your inquiries and
            solve any potential issues you might have with the requested
            service.
          </li>
          <li>
            <strong>
              To evaluate and improve our Services, products, marketing, and
              your experience.
            </strong>{" "}
            We may process your information when we believe it is necessary to
            identify usage trends, determine the effectiveness of our
            promotional campaigns, and to evaluate and improve our Services,
            products, marketing, and your experience.
          </li>
          <li>
            <strong>To identify usage trends.</strong> We may process
            information about how you use our Services to better understand how
            they are being used so we can improve them.
          </li>
        </ul>
      </div>

      <div id="whoshare" className="mb-8">
        <h2 className="text-2xl font-bold mb-4">
          3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
        </h2>

        <p className="mb-4">
          <em>
            <strong>In Short:</strong> We may share information in specific
            situations described in this section and/or with the following third
            parties.
          </em>
        </p>

        <p className="mb-4">
          We may need to share your personal information in the following
          situations:
        </p>

        <ul className="list-disc pl-8 mb-4">
          <li>
            <strong>Business Transfers.</strong> We may share or transfer your
            information in connection with, or during negotiations of, any
            merger, sale of company assets, financing, or acquisition of all or
            a portion of our business to another company.
          </li>
          <li>
            <strong>Offer Wall.</strong> Our application(s) may display a
            third-party hosted "offer wall." Such an offer wall allows
            third-party advertisers to offer virtual currency, gifts, or other
            items to users in return for the acceptance and completion of an
            advertisement offer.
          </li>
        </ul>
      </div>

      <div id="cookies" className="mb-8">
        <h2 className="text-2xl font-bold mb-4">
          4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
        </h2>

        <p className="mb-4">
          <em>
            <strong>In Short:</strong> We may use cookies and other tracking
            technologies to collect and store your information.
          </em>
        </p>

        <p className="mb-4">
          We may use cookies and similar tracking technologies (like web beacons
          and pixels) to gather information when you interact with our Services.
          Some online tracking technologies help us maintain the security of our
          Services and your account, prevent crashes, fix bugs, save your
          preferences, and assist with basic site functions.
        </p>

        <p className="mb-4">
          We also permit third parties and service providers to use online
          tracking technologies on our Services for analytics and advertising,
          including to help manage and display advertisements, to tailor
          advertisements to your interests, or to send abandoned shopping cart
          reminders (depending on your communication preferences).
        </p>
      </div>

      <div id="ai" className="mb-8">
        <h2 className="text-2xl font-bold mb-4">
          5. DO WE OFFER ARTIFICIAL INTELLIGENCE-BASED PRODUCTS?
        </h2>

        <p className="mb-4">
          <em>
            <strong>In Short:</strong> We offer products, features, or tools
            powered by artificial intelligence, machine learning, or similar
            technologies.
          </em>
        </p>
        <p className="mb-4">
          As part of our Services, we offer products, features, or tools powered
          by artificial intelligence, machine learning, or similar technologies
          (collectively, "AI Products"). These tools are designed to enhance
          your experience and provide you with innovative solutions. The terms
          in this Privacy Notice govern your use of the AI Products within our
          Services.
        </p>

        <h3 className="text-xl font-bold mb-4">Use of AI Technologies</h3>

        <p className="mb-4">
          We provide the AI Products through third-party service providers ("AI
          Service Providers"), including Anthropic, OpenAI, and Amazon Web
          Services (AWS) AI. As outlined in this Privacy Notice, your input,
          output, and personal information will be shared with and processed by
          these AI Service Providers to enable your use of our AI Products for
          purposes outlined in "WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL
          INFORMATION?" You must not use the AI Products in any way that
          violates the terms or policies of any AI Service Provider.
        </p>

        <h3 className="text-xl font-bold mb-4">Our AI Products</h3>

        <p className="mb-4">
          Our AI Products are designed for the following functions:
        </p>

        <ul className="list-disc pl-8 mb-4">
          <li>Text analysis</li>
          <li>AI automation</li>
          <li>AI document generation</li>
        </ul>

        <h3 className="text-xl font-bold mb-4">
          How We Process Your Data Using AI
        </h3>

        <p className="mb-4">
          All personal information processed using our AI Products is handled in
          line with our Privacy Notice and our agreement with third parties.
          This ensures high security and safeguards your personal information
          throughout the process, giving you peace of mind about your data's
          safety.
        </p>
      </div>

      <div id="sociallogins" className="mb-8">
        <h2 className="text-2xl font-bold mb-4">
          6. HOW DO WE HANDLE YOUR SOCIAL LOGINS?
        </h2>

        <p className="mb-4">
          <em>
            <strong>In Short:</strong> If you choose to register or log in to
            our Services using a social media account, we may have access to
            certain information about you.
          </em>
        </p>

        <p className="mb-4">
          Our Services offer you the ability to register and log in using your
          third-party social media account details (like your Facebook or X
          logins). Where you choose to do this, we will receive certain profile
          information about you from your social media provider. The profile
          information we receive may vary depending on the social media provider
          concerned, but will often include your name, email address, friends
          list, and profile picture, as well as other information you choose to
          make public on such a social media platform.
        </p>

        <p className="mb-4">
          We will use the information we receive only for the purposes that are
          described in this Privacy Notice or that are otherwise made clear to
          you on the relevant Services. Please note that we do not control, and
          are not responsible for, other uses of your personal information by
          your third-party social media provider. We recommend that you review
          their privacy notice to understand how they collect, use, and share
          your personal information, and how you can set your privacy
          preferences on their sites and apps.
        </p>
      </div>

      <div id="inforetain" className="mb-8">
        <h2 className="text-2xl font-bold mb-4">
          7. HOW LONG DO WE KEEP YOUR INFORMATION?
        </h2>

        <p className="mb-4">
          <em>
            <strong>In Short:</strong> We keep your information for as long as
            necessary to fulfill the purposes outlined in this Privacy Notice
            unless otherwise required by law.
          </em>
        </p>

        <p className="mb-4">
          We will only keep your personal information for as long as it is
          necessary for the purposes set out in this Privacy Notice, unless a
          longer retention period is required or permitted by law (such as tax,
          accounting, or other legal requirements). No purpose in this notice
          will require us keeping your personal information for longer than the
          period of time in which users have an account with us.
        </p>

        <p className="mb-4">
          When we have no ongoing legitimate business need to process your
          personal information, we will either delete or anonymize such
          information, or, if this is not possible (for example, because your
          personal information has been stored in backup archives), then we will
          securely store your personal information and isolate it from any
          further processing until deletion is possible.
        </p>
      </div>

      <div id="infosafe" className="mb-8">
        <h2 className="text-2xl font-bold mb-4">
          8. HOW DO WE KEEP YOUR INFORMATION SAFE?
        </h2>

        <p className="mb-4">
          <em>
            <strong>In Short:</strong> We aim to protect your personal
            information through a system of organizational and technical
            security measures.
          </em>
        </p>

        <p className="mb-4">
          We have implemented appropriate and reasonable technical and
          organizational security measures designed to protect the security of
          any personal information we process. However, despite our safeguards
          and efforts to secure your information, no electronic transmission
          over the Internet or information storage technology can be guaranteed
          to be 100% secure, so we cannot promise or guarantee that hackers,
          cybercriminals, or other unauthorized third parties will not be able
          to defeat our security and improperly collect, access, steal, or
          modify your information. Although we will do our best to protect your
          personal information, transmission of personal information to and from
          our Services is at your own risk. You should only access the Services
          within a secure environment.
        </p>
      </div>

      <div id="infominors" className="mb-8">
        <h2 className="text-2xl font-bold mb-4">
          9. DO WE COLLECT INFORMATION FROM MINORS?
        </h2>

        <p className="mb-4">
          <em>
            <strong>In Short:</strong> We do not knowingly collect data from or
            market to children under 18 years of age.
          </em>
        </p>

        <p className="mb-4">
          We do not knowingly collect, solicit data from, or market to children
          under 18 years of age, nor do we knowingly sell such personal
          information. By using the Services, you represent that you are at
          least 18 or that you are the parent or guardian of such a minor and
          consent to such minor dependent's use of the Services. If we learn
          that personal information from users less than 18 years of age has
          been collected, we will deactivate the account and take reasonable
          measures to promptly delete such data from our records. If you become
          aware of any data we may have collected from children under age 18,
          please contact us at support@policenarratives.ai.
        </p>
      </div>

      <div id="privacyrights" className="mb-8">
        <h2 className="text-2xl font-bold mb-4">
          10. WHAT ARE YOUR PRIVACY RIGHTS?
        </h2>

        <p className="mb-4">
          <em>
            <strong>In Short:</strong> You may review, change, or terminate your
            account at any time, depending on your country, province, or state
            of residence.
          </em>
        </p>

        <p className="mb-4">
          <strong>
            <u>Withdrawing your consent:</u>
          </strong>{" "}
          If we are relying on your consent to process your personal
          information, which may be express and/or implied consent depending on
          the applicable law, you have the right to withdraw your consent at any
          time. You can withdraw your consent at any time by contacting us by
          using the contact details provided in the section "HOW CAN YOU CONTACT
          US ABOUT THIS NOTICE?" below.
        </p>

        <p className="mb-4">
          However, please note that this will not affect the lawfulness of the
          processing before its withdrawal nor, when applicable law allows, will
          it affect the processing of your personal information conducted in
          reliance on lawful processing grounds other than consent.
        </p>

        <p className="mb-4">
          <strong>
            <u>Opting out of marketing and promotional communications:</u>
          </strong>{" "}
          You can unsubscribe from our marketing and promotional communications
          at any time by clicking on the unsubscribe link in the emails that we
          send, or by contacting us using the details provided in the section
          "HOW CAN YOU CONTACT US ABOUT THIS NOTICE?" below. You will then be
          removed from the marketing lists. However, we may still communicate
          with you — for example, to send you service-related messages that are
          necessary for the administration and use of your account, to respond
          to service requests, or for other non-marketing purposes.
        </p>

        <h3 className="text-xl font-bold mb-4">Account Information</h3>

        <p className="mb-4">
          If you would at any time like to review or change the information in
          your account or terminate your account, you can contact us using the
          contact information provided.
        </p>

        <p className="mb-4">
          Upon your request to terminate your account, we will deactivate or
          delete your account and information from our active databases.
          However, we may retain some information in our files to prevent fraud,
          troubleshoot problems, assist with any investigations, enforce our
          legal terms and/or comply with applicable legal requirements.
        </p>

        <p className="mb-4">
          <strong>
            <u>Cookies and similar technologies:</u>
          </strong>{" "}
          Most Web browsers are set to accept cookies by default. If you prefer,
          you can usually choose to set your browser to remove cookies and to
          reject cookies. If you choose to remove cookies or reject cookies,
          this could affect certain features or services of our Services.
        </p>

        <p className="mb-4">
          If you have questions or comments about your privacy rights, you may
          email us at support@policenarratives.ai.
        </p>
      </div>

      <div id="DNT" className="mb-8">
        <h2 className="text-2xl font-bold mb-4">
          11. CONTROLS FOR DO-NOT-TRACK FEATURES
        </h2>

        <p className="mb-4">
          Most web browsers and some mobile operating systems and mobile
          applications include a Do-Not-Track ("DNT") feature or setting you can
          activate to signal your privacy preference not to have data about your
          online browsing activities monitored and collected. At this stage, no
          uniform technology standard for recognizing and implementing DNT
          signals has been finalized. As such, we do not currently respond to
          DNT browser signals or any other mechanism that automatically
          communicates your choice not to be tracked online. If a standard for
          online tracking is adopted that we must follow in the future, we will
          inform you about that practice in a revised version of this Privacy
          Notice.
        </p>

        <p className="mb-4">
          California law requires us to let you know how we respond to web
          browser DNT signals. Because there currently is not an industry or
          legal standard for recognizing or honoring DNT signals, we do not
          respond to them at this time.
        </p>
      </div>

      <div id="uslaws" className="mb-8">
        <h2 className="text-2xl font-bold mb-4">
          12. DO UNITED STATES RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
        </h2>

        <p className="mb-4">
          <em>
            <strong>In Short:</strong> If you are a resident of California,
            Colorado, Connecticut, Delaware, Florida, Indiana, Iowa, Kentucky,
            Minnesota, Montana, Nebraska, New Hampshire, New Jersey, Oregon,
            Tennessee, Texas, Utah, or Virginia, you may have the right to
            request access to and receive details about the personal information
            we maintain about you and how we have processed it, correct
            inaccuracies, get a copy of, or delete your personal information.
            You may also have the right to withdraw your consent to our
            processing of your personal information. These rights may be limited
            in some circumstances by applicable law. More information is
            provided below.
          </em>
        </p>

        <h3 className="text-xl font-bold mb-4">
          Categories of Personal Information We Collect
        </h3>

        <table className="w-full mb-4 border-collapse border border-gray-300">
          <thead>
            <tr className="bg-gray-100">
              <th className="border border-gray-300 p-2 text-left">Category</th>
              <th className="border border-gray-300 p-2 text-left">Examples</th>
              <th className="border border-gray-300 p-2 text-center">
                Collected
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="border border-gray-300 p-2">A. Identifiers</td>
              <td className="border border-gray-300 p-2">
                Contact details, such as real name, alias, postal address,
                telephone or mobile contact number, unique personal identifier,
                online identifier, Internet Protocol address, email address, and
                account name
              </td>
              <td className="border border-gray-300 p-2 text-center">YES</td>
            </tr>
            <tr>
              <td className="border border-gray-300 p-2">
                B. Personal information categories listed in the California
                Customer Records statute
              </td>
              <td className="border border-gray-300 p-2">
                Name, contact information, education, employment, employment
                history, and financial information
              </td>
              <td className="border border-gray-300 p-2 text-center">YES</td>
            </tr>
            <tr>
              <td className="border border-gray-300 p-2">
                C. Protected classification characteristics under state or
                federal law
              </td>
              <td className="border border-gray-300 p-2">
                Gender, age, date of birth, race and ethnicity, national origin,
                marital status, and other demographic data
              </td>
              <td className="border border-gray-300 p-2 text-center">NO</td>
            </tr>
            <tr>
              <td className="border border-gray-300 p-2">
                D. Commercial information
              </td>
              <td className="border border-gray-300 p-2">
                Transaction information, purchase history, financial details,
                and payment information
              </td>
              <td className="border border-gray-300 p-2 text-center">NO</td>
            </tr>
            <tr>
              <td className="border border-gray-300 p-2">
                E. Biometric information
              </td>
              <td className="border border-gray-300 p-2">
                Fingerprints and voiceprints
              </td>
              <td className="border border-gray-300 p-2 text-center">NO</td>
            </tr>
            <tr>
              <td className="border border-gray-300 p-2">
                F. Internet or other similar network activity
              </td>
              <td className="border border-gray-300 p-2">
                Browsing history, search history, online behavior, interest
                data, and interactions with our and other websites,
                applications, systems, and advertisements
              </td>
              <td className="border border-gray-300 p-2 text-center">NO</td>
            </tr>
            <tr>
              <td className="border border-gray-300 p-2">
                G. Geolocation data
              </td>
              <td className="border border-gray-300 p-2">Device location</td>
              <td className="border border-gray-300 p-2 text-center">NO</td>
            </tr>
            <tr>
              <td className="border border-gray-300 p-2">
                H. Audio, electronic, visual, thermal, olfactory, or similar
                information
              </td>
              <td className="border border-gray-300 p-2">
                Images and audio, video or call recordings created in connection
                with our business activities
              </td>
              <td className="border border-gray-300 p-2 text-center">NO</td>
            </tr>
            <tr>
              <td className="border border-gray-300 p-2">
                I. Professional or employment-related information
              </td>
              <td className="border border-gray-300 p-2">
                Business contact details in order to provide you our Services at
                a business level or job title, work history, and professional
                qualifications if you apply for a job with us
              </td>
              <td className="border border-gray-300 p-2 text-center">YES</td>
            </tr>
            <tr>
              <td className="border border-gray-300 p-2">
                J. Education Information
              </td>
              <td className="border border-gray-300 p-2">
                Student records and directory information
              </td>
              <td className="border border-gray-300 p-2 text-center">NO</td>
            </tr>
            <tr>
              <td className="border border-gray-300 p-2">
                K. Inferences drawn from collected personal information
              </td>
              <td className="border border-gray-300 p-2">
                Inferences drawn from any of the collected personal information
                listed above to create a profile or summary about, for example,
                an individual's preferences and characteristics
              </td>
              <td className="border border-gray-300 p-2 text-center">NO</td>
            </tr>
            <tr>
              <td className="border border-gray-300 p-2">
                L. Sensitive personal Information
              </td>
              <td className="border border-gray-300 p-2">
                Account login information
              </td>
              <td className="border border-gray-300 p-2 text-center">YES</td>
            </tr>
          </tbody>
        </table>
        <h3 className="text-xl font-bold mb-4">Your Rights</h3>

        <p className="mb-4">
          You have rights under certain US state data protection laws. However,
          these rights are not absolute, and in certain cases, we may decline
          your request as permitted by law. These rights include:
        </p>

        <ul className="list-disc pl-8 mb-4">
          <li>
            <strong>Right to know</strong> whether or not we are processing your
            personal data
          </li>
          <li>
            <strong>Right to access</strong> your personal data
          </li>
          <li>
            <strong>Right to correct</strong> inaccuracies in your personal data
          </li>
          <li>
            <strong>Right to request</strong> the deletion of your personal data
          </li>
          <li>
            <strong>Right to obtain a copy</strong> of the personal data you
            previously shared with us
          </li>
          <li>
            <strong>Right to non-discrimination</strong> for exercising your
            rights
          </li>
          <li>
            <strong>Right to opt out</strong> of the processing of your personal
            data if it is used for targeted advertising (or sharing as defined
            under California's privacy law), the sale of personal data, or
            profiling in furtherance of decisions that produce legal or
            similarly significant effects ("profiling")
          </li>
        </ul>

        <p className="mb-4">
          Depending upon the state where you live, you may also have the
          following rights:
        </p>

        <ul className="list-disc pl-8 mb-4">
          <li>
            Right to access the categories of personal data being processed (as
            permitted by applicable law, including Minnesota's privacy law)
          </li>
          <li>
            Right to obtain a list of the categories of third parties to which
            we have disclosed personal data (as permitted by applicable law,
            including California's and Delaware's privacy law)
          </li>
          <li>
            Right to obtain a list of specific third parties to which we have
            disclosed personal data (as permitted by applicable law, including
            Minnesota's and Oregon's privacy law)
          </li>
          <li>
            Right to review, understand, question, and correct how personal data
            has been profiled (as permitted by applicable law, including
            Minnesota's privacy law)
          </li>
          <li>
            Right to limit use and disclosure of sensitive personal data (as
            permitted by applicable law, including California's privacy law)
          </li>
          <li>
            Right to opt out of the collection of sensitive data and personal
            data collected through the operation of a voice or facial
            recognition feature (as permitted by applicable law, including
            Florida's privacy law)
          </li>
        </ul>
      </div>

      <div id="policyupdates" className="mb-8">
        <h2 className="text-2xl font-bold mb-4">
          13. DO WE MAKE UPDATES TO THIS NOTICE?
        </h2>

        <p className="mb-4">
          <em>
            <strong>In Short:</strong> Yes, we will update this notice as
            necessary to stay compliant with relevant laws.
          </em>
        </p>

        <p className="mb-4">
          We may update this Privacy Notice from time to time. The updated
          version will be indicated by an updated "Revised" date at the top of
          this Privacy Notice. If we make material changes to this Privacy
          Notice, we may notify you either by prominently posting a notice of
          such changes or by directly sending you a notification. We encourage
          you to review this Privacy Notice frequently to be informed of how we
          are protecting your information.
        </p>
      </div>

      <div id="contact" className="mb-8">
        <h2 className="text-2xl font-bold mb-4">
          14. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
        </h2>

        <p className="mb-4">
          If you have questions or comments about this notice, you may email us
          at support@policenarratives.ai or contact us by post at:
        </p>

        <p className="mb-4">
          MILLIONHARI LLC
          <br />
          1030 N Alvarado St
          <br />
          Ste 303
          <br />
          Los Angeles, CA 90026
          <br />
          United States
        </p>
      </div>

      <div id="request" className="mb-8">
        <h2 className="text-2xl font-bold mb-4">
          15. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM
          YOU?
        </h2>

        <p className="mb-4">
          Based on the applicable laws of your country or state of residence in
          the US, you may have the right to request access to the personal
          information we collect from you, details about how we have processed
          it, correct inaccuracies, or delete your personal information. You may
          also have the right to withdraw your consent to our processing of your
          personal information. These rights may be limited in some
          circumstances by applicable law. To request to review, update, or
          delete your personal information, please fill out and submit a{" "}
          <a href="https://app.termly.io/notify/0f0ebd47-a08e-4053-8438-964279051be5">
            data subject access request.
          </a>
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;

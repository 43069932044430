import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./index.css";
import { AuthProvider } from "./context/AuthContext";
import App from "./components/App";
import Login from "./components/Login";
import Signup from "./components/Signup";
import Dashboard from "./components/Dashboard";
import Settings from "./components/Settings";
import ProtectedRoute from "./components/ProtectedRoute";
import reportWebVitals from "./reportWebVitals";
import Subscription from "./components/Subscription";
import axios from "axios";
import PrivacyPolicy from "./components/pages/PrivacyPolicy";

axios.defaults.withCredentials = true;

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <AuthProvider>
    <Router>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/health" element={<h3>The App is Healthy</h3>} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/subscription" element={<Subscription />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route element={<ProtectedRoute />}>
          <Route element={<Dashboard />}>
            {/* Cases routes */}
            <Route path="cases" element={null} />
            <Route path="cases/:id" element={null} />

            {/* Trash routes */}
            <Route path="trash" element={null} />
            <Route path="trash/:id" element={null} />

            {/* Settings route */}
            <Route path="settings" element={<Settings />} />
          </Route>
        </Route>
      </Routes>
    </Router>
  </AuthProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

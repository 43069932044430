import { ButtonHTMLAttributes, isValidElement, ReactNode } from "react";
import RcTooltip from "rc-tooltip";
import "rc-tooltip/assets/bootstrap.css";

export type TooltipPosition =
  | "top"
  | "left"
  | "right"
  | "bottom"
  | "topLeft"
  | "topRight"
  | "bottomLeft"
  | "bottomRight"
  | "leftTop"
  | "leftBottom"
  | "rightTop"
  | "rightBottom";

interface TooltipProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: ReactNode;
  overlay?: string;
  placement?: TooltipPosition;
}

export default function Tooltip({
  children,
  overlay,
  placement = "bottom",
  ...props
}: TooltipProps) {
  // Ensure children is a valid React element
  const child = isValidElement(children) ? children : <span>{children}</span>;

  return (
    <RcTooltip
      overlay={overlay}
      placement={placement}
      mouseEnterDelay={0.5}
      mouseLeaveDelay={0}
      overlayInnerStyle={{
        marginTop: "-4px",
      }}
      {...props}
    >
      {child}
    </RcTooltip>
  );
}

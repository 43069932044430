import { useNavigate, useSearchParams } from "react-router-dom";
import cn from "classnames";
import Button from "./common/Button";
import { useAuth } from "../context/AuthContext";
import { loadStripe } from "@stripe/stripe-js";
import axios from "axios";

// Initialize Stripe
const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_PUBLIC_KEY ||
    "pk_live_51PydONG4bf6K0RLmdfEJzwpzVpDCNNdt0HjC5sn5QokyLJij6TflI306Xoi69SSqHORZ9cPnt3xw2leQx9rrYb0B00nka5yr7n"
);

interface CardProps {
  title: string;
  description?: string;
  price?: string;
  priceSubtitle?: string;
  items?: string[];
  cta?: string;
  badgeText?: string;
  featured?: boolean;
  priceId?: string;
}

function Card({
  title,
  description,
  price,
  items = [],
  featured,
  priceSubtitle,
  badgeText,
  priceId,
}: CardProps) {
  const navigate = useNavigate();
  const { isAuthenticated } = useAuth();

  const handleCheckout = async () => {
    if (!isAuthenticated) {
      navigate("/login?redirectto=subscription");
      return;
    }

    try {
      const stripe = await stripePromise;
      if (!stripe) throw new Error("Stripe failed to initialize");

      // Create checkout session using axios
      const response = await axios.post(
        "/api/create-checkout-session",
        { priceId },
        { withCredentials: true }
      );

      // Redirect to checkout
      const result = await stripe.redirectToCheckout({
        sessionId: response.data.sessionId,
      });

      if (result.error) {
        throw new Error(result.error.message);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <div
      className={cn(
        "grid col-span-4 min-w-96 bg-white border-gray-200 border-2 rounded-xl p-6 gap-y-4 relative",
        featured && "!border-blue-500"
      )}
    >
      {featured && (
        <div className="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-blue-500 text-white text-center flex items-center justify-center py-1 px-2 text-xs rounded-btn border-2 border-blue-500 font-bold">
          Most Popular
        </div>
      )}
      <div className="flex justify-between">
        <h2 className="m-0">{title}</h2>
        {badgeText && (
          <div className="flex bg-blue-50 px-2 text-blue-500 rounded-lg text-sm items-center justify-center">
            <span className="font-medium">{badgeText}</span>
          </div>
        )}
      </div>
      <p className="text-gray-500 m-0">{description}</p>
      <div className="flex items-center justify-between">
        <div className="flex items-center">
          <p className="text-3xl font-black m-0">{price}</p>
          <p className="text-sm m-0 max-h-fit ml-1 text-gray-600">/month</p>
        </div>
        {priceSubtitle && (
          <div className="text-sm text-gray-400">{priceSubtitle}</div>
        )}
      </div>
      <div>
        {items.length &&
          items.map((x, i) => (
            <label className="label flex items-start justify-start p-1">
              <input
                type="checkbox"
                className="checkbox checkbox-xs mt-1 peer border-blue-500 [--chkbg:theme(colors.blue.500)] [--chkfg:theme(colors.blue.50)] checked:border-blue-500 cursor-default"
                checked
              />
              <p className="mt-[1px] label-text ml-2 text-sm mb-0">{x}</p>
            </label>
          ))}
      </div>
      <Button
        variant={featured ? "primary" : "secondary"}
        className="w-full"
        onClick={handleCheckout}
      >
        Choose Plan
      </Button>
    </div>
  );
}

function Subscription() {
  const [searchParams] = useSearchParams();
  const trialExpired = searchParams.get("trial_expired");

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-blue-50 prose">
      {trialExpired && (
        <p className="text-sm">
          Subscribe to a plan to continue using Police Narratives AI
        </p>
      )}
      <div className="max-w-6xl">
        <h1 className="mb-8 text-center">Choose the right plan for you</h1>
        <div className="grid grid-cols-12 gap-8 w-full">
          <Card
            title="Monthly"
            description="Low commitment for testing. Billed monthly."
            price="$39.99"
            priceId={
              process.env.REACT_APP_MONTHLY_PRICE_ID ||
              "price_1QHc47G4bf6K0RLmKIjLqPes"
            }
            items={[
              "5 audio log free trial",
              "Intelligent report score",
              "AI-powered narrative generation",
              "Department-specific templates",
              "24/7 incident report assistance",
              "Evaluate written reports",
              "Audio log transcription and listening",
            ]}
          />
          <Card
            title="Annually"
            description="The best bang for your buck. Billed annually."
            price="$29.99"
            priceId={
              process.env.REACT_APP_ANNUAL_PRICE_ID ||
              "price_1QFX26G4bf6K0RLmXCvsuNuO"
            }
            items={[
              "5 audio log free trial",
              "Intelligent report score",
              "AI-powered narrative generation",
              "Department-specific templates",
              "24/7 incident report assistance",
              "Evaluate written reports",
              "Audio log transcription and listening",
            ]}
            badgeText="Save $120"
            featured
          />
          <Card
            title="Semi-Annually"
            description="Extended commitment with a great price. Billed every six months."
            price="$34.99"
            priceId={
              process.env.REACT_APP_SEMIANNUAL_PRICE_ID ||
              "price_1QHc47G4bf6K0RLmQtOE1ebB"
            }
            items={[
              "5 audio log free trial",
              "Intelligent report score",
              "AI-powered narrative generation",
              "Department-specific templates",
              "24/7 incident report assistance",
              "Evaluate written reports",
              "Audio log transcription and listening",
            ]}
          />
        </div>
      </div>
    </div>
  );
}

export default Subscription;

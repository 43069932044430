import { useNavigate } from "react-router-dom";
import Button from "./common/Button";
import { ReactComponent as Logo } from "../police-narratives-ai.svg";

function App() {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/login");
  };
  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="flex flex-col items-center space-y-6">
        <div className="max-w-80">
          <Logo className="w-full h-auto" />
        </div>
        <Button variant="primary" onClick={handleClick} className="w-full">
          Login
        </Button>
        <a href="/privacy-policy">Privacy policy</a>
      </div>
    </div>
  );
}

export default App;

import { useEffect, useState, useCallback, useRef, useMemo } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { debounce } from "lodash";
import Cases from "./Cases/Cases";
import Editor from "./Editor";
import axios from "axios";
import { CaseScore, Case as CaseType, Suggestion } from "../db/schema";

interface CasesLayoutProps {
  setSelectedCaseId: React.Dispatch<React.SetStateAction<string>>;
  setCaseContent: React.Dispatch<
    React.SetStateAction<{ data: string } | undefined>
  >;
  setHasCases: React.Dispatch<React.SetStateAction<boolean>>;
  setCaseScore: React.Dispatch<React.SetStateAction<CaseScore | undefined>>;
  setCaseSuggestions: React.Dispatch<
    React.SetStateAction<Suggestion[] | undefined>
  >;
  setCaseAudioUrl: React.Dispatch<React.SetStateAction<string | undefined>>;
  caseSuggestions: Suggestion[] | undefined;
  newCase: CaseType | null;
  selectedCaseId: string;
  caseContent: { data: string } | undefined;
  hasCases: boolean;
  caseScore: CaseScore | undefined;
  caseAudioUrl?: string;
}

function CasesLayout({
  setSelectedCaseId,
  setCaseContent,
  setHasCases,
  setCaseScore,
  setCaseSuggestions,
  setCaseAudioUrl,
  caseSuggestions,
  newCase,
  selectedCaseId,
  caseContent,
  hasCases,
  caseScore,
  caseAudioUrl,
}: CasesLayoutProps) {
  const { id: route_id } = useParams<{ id: string }>();
  const DEBOUNCE_TIME = 500;
  const navigate = useNavigate();
  const [isCaseLoading, setIsCaseLoading] = useState<boolean>(false);
  const location = useLocation();
  const page = location.pathname.split("/")[1];
  const isInitialMount = useRef(true);

  const handleSelectedCase = useCallback(
    async (caseId: string) => {
      if (!caseId) {
        setSelectedCaseId("");
        setCaseContent(undefined);
        setCaseAudioUrl("");
        return;
      }
      setIsCaseLoading(true);
      setSelectedCaseId(caseId);
      try {
        const response = await axios.get(`/api/cases/${caseId}`, {
          withCredentials: true,
        });
        console.log("handleSelectedCase");
        setCaseContent(response.data.case.content);
        setCaseSuggestions(response.data.suggestions);
        setCaseScore(response.data.score);
        setCaseAudioUrl(response.data.case.audioUrl);
        navigate(`/${page}/${caseId}`);
      } catch (error) {
        console.error("Error fetching case:", error);
      } finally {
        setIsCaseLoading(false);
      }
    },
    [
      navigate,
      page,
      setCaseContent,
      setCaseScore,
      setCaseSuggestions,
      setSelectedCaseId,
    ]
  );

  const handleEditorChange = useMemo(
    () =>
      debounce(async ({ content, caseId }) => {
        if (
          caseId &&
          content.data !== "<h1></h1>" &&
          content.data !== caseContent?.data
        ) {
          try {
            const response = await axios.patch(
              `/api/cases/${caseId}`,
              { content },
              {
                withCredentials: true,
              }
            );
            if (response.status === 200) {
              console.log("Case content updated successfully");
              setCaseContent(content);
            }
          } catch (error) {
            console.error("Error updating case content:", error);
          }
        }
      }, DEBOUNCE_TIME),
    [caseContent, setCaseContent]
  );

  const editorChangeHandler = useCallback(
    ({ content, caseId }) => {
      console.log("editorChangeHandler");
      if (content.data !== caseContent?.data) {
        handleEditorChange({ content, caseId });
      }
    },
    [handleEditorChange, caseContent]
  );

  const setInitialCaseContentOnPageLoad = useCallback(
    async (caseId: string) => {
      setIsCaseLoading(true);
      try {
        const response = await axios.get(`/api/cases/${caseId}`, {
          withCredentials: true,
        });
        console.log("setInitialCaseContentOnPageLoad");
        setCaseContent(response.data.case.content);
        setCaseSuggestions(response.data.suggestions);
        setCaseScore(response.data.score);
        setCaseAudioUrl(response.data.case.audioUrl || "");
      } catch (error) {
        console.error("Error fetching case:", error);
        setCaseContent(undefined);
      } finally {
        setIsCaseLoading(false);
      }
    },
    [setCaseContent, setCaseScore, setCaseSuggestions]
  );

  // Clean up the debounced function when component unmounts
  useEffect(() => {
    return () => {
      handleEditorChange.cancel();
    };
  }, [handleEditorChange]);

  const handleCasesUpdate = useCallback(
    (casesExist: boolean) => {
      setHasCases(casesExist);
      if (!casesExist) {
        setSelectedCaseId("");
        setCaseContent(undefined);
      }
    },
    [setCaseContent, setHasCases, setSelectedCaseId]
  );

  // If route id is passed in, load that case
  useEffect(() => {
    if (isInitialMount.current && route_id) {
      setSelectedCaseId(route_id);
      setInitialCaseContentOnPageLoad(route_id);
      isInitialMount.current = false;
    }
  }, [route_id, setInitialCaseContentOnPageLoad, setSelectedCaseId]);

  return (
    <>
      <Cases
        selectedCaseId={selectedCaseId}
        handleSelectedCase={handleSelectedCase}
        setCaseContent={setCaseContent}
        newCase={newCase}
        onCasesUpdate={handleCasesUpdate}
        caseContent={caseContent}
        isCaseLoading={isCaseLoading}
        page={page}
      />
      <div className="flex-1 flex flex-col overflow-auto">
        <Editor
          caseContent={caseContent}
          caseSuggestions={caseSuggestions}
          caseScore={caseScore}
          caseAudioUrl={caseAudioUrl}
          onChange={editorChangeHandler}
          caseId={selectedCaseId}
          hasCases={hasCases}
          page={page}
        />
      </div>
    </>
  );
}

export default CasesLayout;
